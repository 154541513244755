import React from "react";
import _ from "lodash";

import ModalWrapper from "../../../common/ModalWrapper";
import RegisteredInventory from "./RegisteredInventory";
import OpenLinkInNewWindowBtn from "components/common/OpenLinkInNewWindowBtn";

const inputClass =
  "bg-white flex border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassDisabled =
  "bg-gray-300 flex border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";
const inputClassSmall =
  "bg-white flex border text-xs border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

const titleClass = "block text-gray-800 text-sm font-bold"; //"font-semibold";

const DEFAULT_RATIO_OPTIONS = [
  [900, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
  [900, 20, 20, 20, 20, 20],
];
const UPR_PROTECTION_MODES = {
  NONE: "NONE",
  DETECTOR: "DETECTOR",
  DETECTOR_AND_X: "DETECTOR_AND_X",
  ALL: "ALL",
};
const TAGGING_FREQUENCY = {
  SESSION_LEVEL: "SESSION_LEVEL",
  REQUEST_LEVEL: "REQUEST_LEVEL",
};

const DEFAULT_CONFIG = {
  enable_optimization: false,
  // code snippet related properties
  group_traffic_ratio_options: [],
  opt_exp_traffic_ratio: "", // "0.38", // new
  // gam related properties
  traffic_ratio_option_index: "",
  upr_protection_mode: "", //"NONE", // new

  registered_inventory: {
    desktop: { enable_optimization: false },
    connecttv: { enable_optimization: false },
    phone: { enable_optimization: false },
    tablet: { enable_optimization: false },
  },

  ab_test_traffic_allocation: {
    tagging_frequency: TAGGING_FREQUENCY.SESSION_LEVEL,
  },
};

class CSTIntegrationControlEditModal extends React.Component {
  constructor(props) {
    super(props);

    const { integration, intConfig } = this.props;
    const newIntConfig = _.defaultsDeep(_.cloneDeep(intConfig), DEFAULT_CONFIG);

    const abTestSection = newIntConfig["ab_test_traffic_allocation"] || {};
    const isAbTestEnabled = _.get(newIntConfig, [
      "ab_test_traffic_allocation",
      "__is_enabled",
    ]);

    const groupRatioOptionsString = JSON.stringify(
      newIntConfig["group_traffic_ratio_options"]
    );
    this.state = {
      isInvalid: false,
      newIntConfig,
      groupRatioOptionsString,
      abTestSection,
      isAbTestEnabled,
      errorObj: {},
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.handleChanged = this.handleChanged.bind(this);
    this.handleAbTestSectionChanged =
      this.handleAbTestSectionChanged.bind(this);
    this.handleSetAbTrafficRatio = this.handleSetAbTrafficRatio.bind(this);
    this.handleAbTestTrafficRatioChanged =
      this.handleAbTestTrafficRatioChanged.bind(this);
    this.handleValidateAbTestSection =
      this.handleValidateAbTestSection.bind(this);
    this.handleIsAbTestEnabled = this.handleIsAbTestEnabled.bind(this);
    this.handleAbTestTaggingFrequencyChanged =
      this.handleAbTestTaggingFrequencyChanged.bind(this);

    this.handleGroupRatioOptionsChanged =
      this.handleGroupRatioOptionsChanged.bind(this);
    this.handleInsertDefaultGroupRatioOptions =
      this.handleInsertDefaultGroupRatioOptions.bind(this);
    this.handleRIChanged = this.handleRIChanged.bind(this);
  }

  handleSave() {
    let { newIntConfig } = this.state;

    if (newIntConfig.ab_test_traffic_allocation) {
      let hasError = this.handleValidateAbTestSection({
        config: newIntConfig.ab_test_traffic_allocation,
      });
      if (hasError) {
        return;
      }

      const ab = newIntConfig.ab_test_traffic_allocation;
      const { configGeneral } = this.props;
      newIntConfig.ab_test_traffic_allocation.traffic_ratio_for_opt_values =
        _.map(configGeneral.managedValues, (v) => {
          return ab.__opt_map[v.id];
        });
      newIntConfig.ab_test_traffic_allocation.traffic_ratio_for_bm_values =
        _.map(configGeneral.benchmarkValues, (v) => {
          return ab.__bm_map[v.id];
        });
    }

    if (
      _.isNil(newIntConfig["opt_exp_traffic_ratio"]) ||
      newIntConfig["opt_exp_traffic_ratio"] === "" ||
      _.isNaN(newIntConfig["opt_exp_traffic_ratio"])
    ) {
      newIntConfig = _.omit(newIntConfig, "opt_exp_traffic_ratio");
    }

    if (
      _.isNil(newIntConfig["upr_protection_mode"]) ||
      newIntConfig["upr_protection_mode"] === "" ||
      _.isNaN(newIntConfig["upr_protection_mode"])
    ) {
      newIntConfig = _.omit(newIntConfig, "upr_protection_mode");
    }

    this.props.handleSave(newIntConfig);
  }

  handleCancel() {
    this.setState({ newIntConfig: null });
    this.props.handleCloseModal();
  }

  handleIsAbTestEnabled() {
    let config = this.state.abTestSection;
    config.__is_enabled = !config.__is_enabled;
    const newIntConfig = {
      ...this.state.newIntConfig,
      ab_test_traffic_allocation: config,
    };
    this.setState({
      abTestSection: config,
      newIntConfig,
      isAbTestEnabled: config.__is_enabled,
    });
  }

  handleChanged({ key, value }) {
    let config = this.state.newIntConfig;
    if (key === "enable_optimization" && value === false) {
      // clear config
      config = { enable_optimization: false };
    } else {
      config[key] = value;
    }
    this.setState({ newIntConfig: config });
  }

  handleAbTestTrafficRatioChanged({ valueId, key, value }) {
    let config = this.state.abTestSection;

    const isOpt = key === "traffic_ratio_for_opt_values";
    config[isOpt ? "__opt_map" : "__bm_map"][valueId] = value;

    // // order should be the same as the values in generalConfig
    // const gValues = this.props.configGeneral[
    //   isOpt
    //     ? "managedValues"
    //     : "benchmarkValues"
    // ];

    const newIntConfig = {
      ...this.state.newIntConfig,
      ab_test_traffic_allocation: config,
    };
    this.setState({ abTestSection: config, newIntConfig });
  }

  handleAbTestSectionChanged({ key, value }) {
    let config = this.state.abTestSection;

    config[key] = parseInt(value);
    if (_.isNaN(config[key])) {
      config[key] = "";
    }

    const newIntConfig = {
      ...this.state.newIntConfig,
      ab_test_traffic_allocation: config,
    };
    this.setState({ abTestSection: config, newIntConfig });
  }

  // matchingValues:
  // [ { "id": 448233050867, "name": "desktop_1_b" }, { "id": 448238477605, "name": "mweb_2_b" } ]
  handleSetAbTrafficRatio({ key, matchingValues }) {
    let config = this.state.abTestSection;
    const isOpt = key === "traffic_ratio_for_opt_values";

    const numOfItems = matchingValues.length;
    const avgRatio = Math.floor(100 / numOfItems);
    let counter = 100;
    _.forEach(matchingValues, (item, index) => {
      // check last ratio
      if (index === numOfItems - 1) {
        config[isOpt ? "__opt_map" : "__bm_map"][item.id] = counter;
      } else {
        config[isOpt ? "__opt_map" : "__bm_map"][item.id] = avgRatio;
        counter -= avgRatio;
      }
    });

    const newIntConfig = {
      ...this.state.newIntConfig,
      ab_test_traffic_allocation: config,
    };
    this.setState({ abTestSection: config, newIntConfig });
  }

  handleAbTestTaggingFrequencyChanged(e) {
    const config = this.state.abTestSection;
    config.tagging_frequency = e.target.value;

    const newIntConfig = {
      ...this.state.newIntConfig,
      ab_test_traffic_allocation: config,
    };
    this.setState({ abTestSection: config, newIntConfig });
  }

  handleValidateAbTestSection({ config }) {
    let errorObj = {};
    let hasError = false;

    if (!config.__is_enabled) {
      this.setState({ errorObj, isInvalid: hasError });
      return hasError;
    }

    // all keys are required
    const keys = [
      "optimization_ratio",
      "traffic_ratio_for_opt_values",
      "traffic_ratio_for_bm_values",
    ];

    _.forEach(keys, (key) => {
      switch (key) {
        case "optimization_ratio": {
          if (_.isNil(config[key]) || config[key] === "") {
            errorObj[key] = `${key} is required`;
            hasError = true;
          }

          if (config[key] >= 0 && config[key] <= 100) {
          } else {
            errorObj[key] = `${key} need to be between 0 and 100`;
            hasError = true;
          }
          break;
        }

        case "traffic_ratio_for_opt_values": {
          // check any missing
          _.forIn(config.__opt_map, (v, k) => {
            if (_.isNil(v)) {
              errorObj[key] = `Every value should have a ratio`;
              hasError = true;
              return;
            }
          });

          // check if sum is 100
          if (_.sum(_.values(config.__opt_map)) !== 100) {
            errorObj[key] = `${key} should add up to 100`;
            hasError = true;
          }
          break;
        }

        case "traffic_ratio_for_bm_values": {
          // check any missing
          _.forIn(config.__bm_map, (v, k) => {
            if (_.isNil(v)) {
              errorObj[key] = `Every value should have a ratio`;
              hasError = true;
              return;
            }
          });

          // check if sum is 100
          if (_.sum(_.values(config.__bm_map)) !== 100) {
            errorObj[key] = `${key} should add up to 100`;
            hasError = true;
          }
          break;
        }
        default:
          break;
      }
    });

    this.setState({ errorObj, isInvalid: hasError });
    return hasError;
  }

  handleGroupRatioOptionsChanged(e) {
    const string = e.target.value;

    const value = string;
    try {
      let config = this.state.newIntConfig;
      const valueString = JSON.parse(value);
      const level1 = _.map(valueString[0], _.parseInt);
      const level2 = _.map(valueString[1], _.parseInt);

      config["group_traffic_ratio_options"] = [level1, level2];
      this.setState({ groupRatioOptionsString: string, newIntConfig: config });
    } catch (err) {
      console.log("invalid json", err);
      this.setState({ groupRatioOptionsString: string });
    }
  }

  handleInsertDefaultGroupRatioOptions() {
    let config = this.state.newIntConfig;
    config["group_traffic_ratio_options"] = DEFAULT_RATIO_OPTIONS;
    this.setState({
      groupRatioOptionsString: JSON.stringify(DEFAULT_RATIO_OPTIONS),
      newIntConfig: config,
    });
  }

  handleRIChanged(riConfig) {
    // console.log("***********************");
    // console.log("ri changed", riConfig);

    let config = this.state.newIntConfig;
    config["registered_inventory"] = riConfig;
    this.setState({
      newIntConfig: config,
    });
  }

  render() {
    const { isModalOpen, integration, configGeneral, networkId } = this.props;
    const {
      isInvalid,
      newIntConfig,
      groupRatioOptionsString,
      isAbTestEnabled,
      abTestSection,
      errorObj,
    } = this.state;

    const isEnabled = newIntConfig["enable_optimization"];

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        showCloseFooter={false}
        handleClose={this.handleCloseModal}
        width="80%"
      >
        <div className="border-b -mx-6 -mt-5 mb-4 bg-blue-100 px-6 py-4 text-xl font-bold text-blue-800">
          {integration}
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: "430px" }}>
          <div>
            <div className="mb-4">
              <label className={titleClass}>Enabled: </label>

              <div className="flex gap-4">
                <label
                  htmlFor={`${integration}_radio_enabled_true`}
                  className="cursor-pointer"
                >
                  <input
                    id={`${integration}_radio_enabled_true`}
                    type="radio"
                    value="true"
                    checked={newIntConfig["enable_optimization"] === true}
                    onChange={() =>
                      this.handleChanged({
                        key: "enable_optimization",
                        value: true,
                      })
                    }
                    required
                  />{" "}
                  True
                </label>

                <label
                  htmlFor={`${integration}_radio_enabled_false`}
                  className="cursor-pointer"
                >
                  <input
                    id={`${integration}_radio_enabled_false`}
                    type="radio"
                    value="false"
                    checked={newIntConfig["enable_optimization"] === false}
                    onChange={() =>
                      this.handleChanged({
                        key: "enable_optimization",
                        value: false,
                      })
                    }
                    required
                  />{" "}
                  False
                </label>
              </div>
            </div>

            {isEnabled && (
              <>
                <div className="mb-4">
                  <label className={titleClass}>
                    Group Traffic Ratio Options:
                  </label>
                  <textarea
                    rows={2}
                    className={inputClass + " w-full"}
                    value={groupRatioOptionsString}
                    onChange={this.handleGroupRatioOptionsChanged}
                  ></textarea>
                  <div>
                    <button
                      type="button"
                      className="border rounded bg-gray-100 px-2 text-xs font-medium hover:bg-gray-300"
                      onClick={this.handleInsertDefaultGroupRatioOptions}
                    >
                      Use: {JSON.stringify(DEFAULT_RATIO_OPTIONS)}
                    </button>
                  </div>
                  {/* <div className="text-xs text-gray-600"></div> */}
                </div>

                <div className="border-b mb-4 pb-4">
                  <label className={titleClass}>
                    Traffic Ratio Option Index:
                  </label>

                  <input
                    className={inputClass + " w-1/4"}
                    type="number"
                    value={newIntConfig["traffic_ratio_option_index"]}
                    onChange={(e) =>
                      this.handleChanged({
                        key: "traffic_ratio_option_index",
                        value: parseInt(e.target.value),
                      })
                    }
                    min={0}
                    step={1}
                  ></input>
                </div>

                <div className="border-b mb-4 pb-4">
                  <div className="text-lg font-semibold">
                    AB Test Allocation
                  </div>
                  {/* <div>{JSON.stringify(abTestSection, null, 4)}</div> */}

                  <div className="mb-4">
                    <label className={titleClass}>Enabled: </label>

                    <div className="flex gap-4">
                      <label
                        htmlFor={`${integration}_abtest_radio_enabled_true`}
                        className="cursor-pointer"
                      >
                        <input
                          id={`${integration}_abtest_radio_enabled_true`}
                          type="radio"
                          value="true"
                          checked={isAbTestEnabled}
                          onChange={this.handleIsAbTestEnabled}
                          required
                        />{" "}
                        True
                      </label>

                      <label
                        htmlFor={`${integration}_abtest_radio_enabled_false`}
                        className="cursor-pointer"
                      >
                        <input
                          id={`${integration}_abtest_radio_enabled_false`}
                          type="radio"
                          value="false"
                          checked={!isAbTestEnabled}
                          onChange={this.handleIsAbTestEnabled}
                          required
                        />{" "}
                        False
                      </label>
                    </div>
                  </div>

                  {isAbTestEnabled && (
                    <>
                      <div className="mb-4">
                        <label className={titleClass}>
                          Optimization Ratio: (0 - 100)
                        </label>

                        <input
                          className={inputClass + " w-1/4"}
                          type="number"
                          value={
                            abTestSection
                              ? abTestSection["optimization_ratio"]
                              : ""
                          }
                          onChange={(e) =>
                            this.handleAbTestSectionChanged({
                              key: "optimization_ratio",
                              value: e.target.value,
                            })
                          }
                          min={0}
                          max={100}
                          step={10}
                        ></input>
                        {errorObj["optimization_ratio"] && (
                          <div className="bg-red-200 px-2 text-sm text-red-800">
                            {errorObj["optimization_ratio"]}
                          </div>
                        )}
                      </div>

                      <div className="mb-4">
                        <label className={titleClass}>
                          Traffic Ratio for OPT Values:
                        </label>

                        <div>
                          {configGeneral.managedValues.map((mv, i) => {
                            const ab = abTestSection;

                            return (
                              <div
                                className="flex items-center gap-1 text-sm"
                                key={mv.id}
                              >
                                <div className="flex items-center">
                                  <input
                                    className={inputClassSmall + " w-24"}
                                    type="number"
                                    value={ab.__opt_map[mv.id] || ""}
                                    onChange={(e) =>
                                      this.handleAbTestTrafficRatioChanged({
                                        valueId: mv.id,
                                        key: "traffic_ratio_for_opt_values",
                                        value: parseInt(e.target.value),
                                      })
                                    }
                                    min={0}
                                    max={100}
                                    step={10}
                                  ></input>
                                  <span className="font-semibold">%</span>
                                </div>
                                <div className="bg-gray-200 px-1 text-xs">
                                  {mv.id} - {mv.name}
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <div>
                          <button
                            type="button"
                            className="border rounded bg-gray-100 px-2 text-xs font-medium hover:bg-gray-300"
                            onClick={() =>
                              this.handleSetAbTrafficRatio({
                                key: "traffic_ratio_for_opt_values",
                                matchingValues: configGeneral.managedValues,
                              })
                            }
                          >
                            Evenly distribute ratio
                          </button>
                        </div>
                        {errorObj["traffic_ratio_for_opt_values"] && (
                          <div className="bg-red-200 px-2 text-sm text-red-800">
                            {errorObj["traffic_ratio_for_opt_values"]}
                          </div>
                        )}
                      </div>

                      <div className="mb-4">
                        <label className={titleClass}>
                          Traffic Ratio for Benchmark Values:
                        </label>

                        <div
                        // className="flex items-center gap-2"
                        >
                          {configGeneral.benchmarkValues.map((mv, i) => {
                            const ab = abTestSection;

                            return (
                              <div
                                className="flex items-center gap-1 text-sm"
                                key={mv.id}
                              >
                                <div className="flex items-center">
                                  <input
                                    className={inputClassSmall + " w-24"}
                                    type="number"
                                    value={ab.__bm_map[mv.id] || ""}
                                    onChange={(e) =>
                                      this.handleAbTestTrafficRatioChanged({
                                        valueId: mv.id,
                                        key: "traffic_ratio_for_bm_values",
                                        value: parseInt(e.target.value),
                                      })
                                    }
                                    min={0}
                                    max={100}
                                    step={10}
                                  ></input>
                                  <span className="font-semibold">%</span>
                                </div>
                                <div className="bg-gray-200 px-1 text-xs">
                                  {mv.id} - {mv.name}
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <div>
                          <button
                            type="button"
                            className="border rounded bg-gray-100 px-2 text-xs font-medium hover:bg-gray-300"
                            onClick={() =>
                              this.handleSetAbTrafficRatio({
                                key: "traffic_ratio_for_bm_values",
                                matchingValues: configGeneral.benchmarkValues,
                              })
                            }
                          >
                            Evenly distribute ratio
                          </button>
                        </div>
                        {errorObj["traffic_ratio_for_bm_values"] && (
                          <div className="bg-red-200 px-2 text-sm text-red-800">
                            {errorObj["traffic_ratio_for_bm_values"]}
                          </div>
                        )}
                      </div>

                      <div className="mb-4">
                        <label className={titleClass}>
                          Tagging Frequency:{" "}
                        </label>

                        <div className="flex gap-4">
                          <label
                            htmlFor={`${integration}_abtest_radio_tagging_session`}
                            className="cursor-pointer"
                          >
                            <input
                              id={`${integration}_abtest_radio_tagging_session`}
                              type="radio"
                              value={TAGGING_FREQUENCY.SESSION_LEVEL}
                              checked={
                                abTestSection.tagging_frequency ===
                                TAGGING_FREQUENCY.SESSION_LEVEL
                              }
                              onChange={
                                this.handleAbTestTaggingFrequencyChanged
                              }
                              required
                            />{" "}
                            Session Level
                          </label>

                          <label
                            htmlFor={`${integration}_abtest_radio_tagging_request`}
                            className="cursor-pointer"
                          >
                            <input
                              id={`${integration}_abtest_radio_tagging_request`}
                              type="radio"
                              value={TAGGING_FREQUENCY.REQUEST_LEVEL}
                              checked={
                                abTestSection.tagging_frequency ===
                                TAGGING_FREQUENCY.REQUEST_LEVEL
                              }
                              onChange={
                                this.handleAbTestTaggingFrequencyChanged
                              }
                              required
                            />{" "}
                            Request Level
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="mb-4">
                  <label className={titleClass}>
                    Optimization Experimental Traffic Ratio: (0 - 0.5)
                  </label>

                  <input
                    className={inputClass + " w-1/4"}
                    type="number"
                    value={newIntConfig["opt_exp_traffic_ratio"]}
                    onChange={(e) =>
                      this.handleChanged({
                        key: "opt_exp_traffic_ratio",
                        value: parseFloat(e.target.value),
                      })
                    }
                    min={0}
                    max={0.5}
                    step={0.01}
                  ></input>
                </div>

                <div className="mb-4">
                  <label className={titleClass}>
                    Publisher UPR Protection Mode:
                  </label>

                  <div className="mb-1 text-sm leading-none text-orange-800">
                    <a
                      href={`${window.location.origin}/#/ops-mgmt/account/network/${networkId}/features`}
                      target="_blank"
                      className="underline"
                    >
                      Network Feature's
                    </a>{" "}
                    "upr_inverse_targeting_mode" needs to be{" "}
                    <b>PROTECT_CST_SPECIFIED</b>
                  </div>

                  <div className="flex gap-4 text-gray-700">
                    {_.keys(UPR_PROTECTION_MODES).map((mode) => {
                      return (
                        <label
                          key={mode}
                          htmlFor={`radio_${mode}`}
                          className="cursor-pointer font-semibold"
                        >
                          <input
                            id={`radio_${mode}`}
                            type="radio"
                            value={mode}
                            checked={
                              newIntConfig["upr_protection_mode"] === mode
                            }
                            onChange={(e) =>
                              this.handleChanged({
                                key: "upr_protection_mode",
                                value: mode,
                              })
                            }
                          />{" "}
                          {mode}
                        </label>
                      );
                    })}
                  </div>
                </div>

                <div className="mb-4">
                  <label className={titleClass}>Registered Inventory: </label>
                  <RegisteredInventory
                    integration={integration}
                    riConfig={newIntConfig["registered_inventory"] || {}}
                    handleRIChanged={this.handleRIChanged}
                  ></RegisteredInventory>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="mt-4 flex flex-row-reverse items-center">
          <div>
            <button
              type="button"
              className={`rounded shadow bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-700`}
              onClick={this.handleSave}
            >
              Save
            </button>
            {/* <button
              type="button"
              className={`px-4 py-2 ${
                // !_.isEmpty(errorObj)
                isInvalid
                  ? "bg-gray-400 text-gray-800"
                  : "bg-blue-500 text-white hover:bg-blue-700"
              }  rounded shadow font-semibold`}
              onClick={this.handleSave}
              // disabled={!_.isEmpty(errorObj)}
              disabled={isInvalid}
            >
              Save
            </button> */}
          </div>
          <div>
            <button
              type="button"
              disabled={false}
              className="px-4 py-2 text-blue-700"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
          {/* <div className="text-red-600">{formErrMsg}</div> */}
        </div>
      </ModalWrapper>
    );
  }
}

export default CSTIntegrationControlEditModal;
