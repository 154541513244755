import React from "react";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

class SelectedStickyFooter extends React.PureComponent {
  constructor(props) {
    super(props);
    const { idList } = props;

    const newIdList = idList
      ? idList.map((item) => {
          if (typeof item === "string") {
            return { key: item, text: item };
          }
          return {
            key: item.key,
            text: item.text,
          };
        })
      : [];

    const key = newIdList[0] ? newIdList[0].key : "";

    this.state = {
      copyKey: key,
      idList: newIdList,
    };

    this.props.handleSelectedByKey(key);

    this.handleCopyKeyChanged = this.handleCopyKeyChanged.bind(this);
  }

  handleCopyKeyChanged(key) {
    this.setState({ copyKey: key });
    this.props.handleSelectedByKey(key);
  }

  render() {
    const { handleClear, selectedItems, actionButtons } = this.props;
    const { copyKey, title, idList } = this.state;

    const selectedString = (selectedItems ? selectedItems : []).join(", ");

    return (
      <div className="fixed bottom-0 left-0 w-full border-t-2 bg-white px-4 py-2">
        <div className="flex items-center gap-2 px-8 text-gray-700">
          <div className="whitespace-no-wrap text-right text-sm">
            {title ? title : "Selected"} (
            {(selectedItems ? selectedItems : []).length}):
          </div>
          <div className="flex-no-wrap flex gap-1 text-sm">
            {idList.map(({ key, text }) => {
              return (
                <button
                  key={key}
                  type="button"
                  className={`rounded px-2 py-1 font-semibold ${
                    copyKey === key
                      ? "bg-indigo-100 text-indigo-800"
                      : "text-gray-700 hover:bg-gray-200 hover:text-indigo-800"
                  }`}
                  onClick={() => this.handleCopyKeyChanged(key)}
                >
                  {text}
                </button>
              );
            })}
          </div>
          <textarea
            rows="1"
            value={selectedString}
            readOnly
            className="border rounded w-full p-1 text-xs"
          ></textarea>
          <div>
            <button
              className="rounded overflow-x-auto px-2 py-1 text-sm text-blue-600 hover:bg-blue-100"
              type="button"
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
          <div>
            <ClickToCopyWrapper copyText={selectedString}></ClickToCopyWrapper>
          </div>
          {actionButtons}
        </div>
      </div>
    );
  }
}

export default SelectedStickyFooter;
