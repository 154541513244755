import React from "react";
import _ from "lodash";
import CustomTargetingKeysSelector from "components/ops-mgmt/account/cst-config/CustomTargetingKeysSelector";
import CustomTargetingValuesSelector from "components/ops-mgmt/account/cst-config/CustomTargetingValuesSelector";

const OPERATOR = {
  IS: "IS",
  IS_NOT: "IS_NOT",
};

const VALUEKEY = {
  ID: "id",
  NAME: "name",
};

const basicInputClass =
  "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-2 block appearance-none leading-normal";

// V1 used in Unit AutoPilot config: Custom Segmentation
// V2 used in CST Config, Custom UPR
class CustomTargetingControlV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // customValue
    // handleAddNewSet
    const {
      keyId = "",
      valueIds = [],
      operator = OPERATOR.IS,
    } = props.customValue;

    this.state = {
      keyId,
      keyName: null,
      valueIds,
      valueIdsString: valueIds ? valueIds.join(",") : "",
      operator,

      errorMsg: null,

      valueKey: VALUEKEY.NAME,
    };

    this.handleKeyIdInputChanged = this.handleKeyIdInputChanged.bind(this);
    this.handleKeyIdChanged = this.handleKeyIdChanged.bind(this);
    this.handleValueIdsInputChanged =
      this.handleValueIdsInputChanged.bind(this);
    this.handleValueIdsChanged = this.handleValueIdsChanged.bind(this);
    this.handleOPChanged = this.handleOPChanged.bind(this);

    this.handleAddCustom = this.handleAddCustom.bind(this);
    this.handleCancelCustom = this.handleCancelCustom.bind(this);

    // this.handleIsUseSelectorChanged =
    //   this.handleIsUseSelectorChanged.bind(this);
  }

  handleAddCustom() {
    const { keyId, valueIdsString, valueIds, operator } = this.state;
    if (!keyId || keyId === "") {
      this.setState({ errorMsg: "Missing Key ID" });
      return;
    }
    if (!valueIdsString || valueIdsString === "") {
      this.setState({ errorMsg: "Missing Value IDs" });
      return;
    }

    // keyId, valueIds needs to be saved as string...
    let finalValue = {
      keyId: keyId + "",
      valueIds: _.map(valueIds, (id) => id + ""),
      operator,
    };
    this.props.handleAddNewSet({ newSet: finalValue, outerIndex: null });

    this.setState({
      errorMsg: null,
    });
  }

  handleCancelCustom() {
    this.setState({
      keyId: "",
      valueIds: [],
      operator: OPERATOR.IS,
      valueIdsString: "",

      errorMsg: null,
    });
    this.props.handleCancelNewSet();
  }

  handleKeyIdInputChanged(e) {
    const keyId = e.target.value;
    this.setState({ keyId });
  }

  handleKeyIdChanged(option) {
    this.setState({
      keyId: option ? option.value : "",
      keyName: option ? option.name : "",
    });
  }

  handleValueIdsInputChanged(e) {
    const valueIdsString = e.target.value;
    this.setState({ valueIdsString });

    try {
      const trimmedString = valueIdsString.replace(/\s/g, ""); // remove whitespace
      const valueIds = _.compact(_.split(trimmedString, ","));
      this.setState({ valueIds });
    } catch (err) {
      console.log(err);
    }
  }

  handleValueIdsChanged(options) {
    const valueIds = _.map(options, "value");
    this.setState({ valueIds, valueIdsString: valueIds.join(",") });
  }

  handleOPChanged(operator) {
    this.setState({ operator });
  }

  render() {
    const { networkId } = this.props;
    const {
      keyId,
      valueIds,
      valueIdsString,
      operator,
      errorMsg,
      valueKey,
      keyName,
    } = this.state;

    return (
      <>
        <div className="rounded border shadow my-2 border-gray-500 bg-white p-2">
          {/* <div className="border-b">New Custom</div> */}
          <div>
            <div className="flex items-center justify-between font-semibold">
              Key ID:
            </div>
            <CustomTargetingKeysSelector
              keyId={keyId}
              networkId={networkId}
              handleOnChange={this.handleKeyIdChanged}
            ></CustomTargetingKeysSelector>
          </div>
          <div>
            <div className="mb-1 mt-2 flex items-end gap-2 font-semibold">
              Value IDs: {valueIds.length > 0 && `(${valueIds.length})`}
              {["id", "name"].map((key) => (
                <div
                  key={key}
                  className={`cursor-pointer rounded-md px-2 font-normal ${
                    valueKey === key
                      ? "bg-blue-400 text-gray-100"
                      : "bg-blue-100 text-blue-900 hover:bg-blue-200"
                  }`}
                  onClick={() => this.setState({ valueKey: key })}
                >
                  Paste by {key}
                </div>
              ))}
            </div>
            <div>
              {" "}
              {keyId && keyName ? (
                <CustomTargetingValuesSelector
                  networkId={networkId}
                  keyId={keyId}
                  keyName={keyName}
                  valueKey={valueKey}
                  managedValueIds={valueIds}
                  isMulti={true}
                  handleOnChange={this.handleValueIdsChanged}
                ></CustomTargetingValuesSelector>
              ) : (
                <span className="text-gray-700">Select Key ID first</span>
              )}
            </div>
          </div>
          <div>
            <div className="font-semibold">Operator</div>

            <div className="flex items-center gap-4">
              <label htmlFor={`custom_is`} className="cursor-pointer">
                <input
                  id={`custom_is`}
                  type="radio"
                  name={`custom_targeting_radio`}
                  value={OPERATOR.IS}
                  checked={operator === OPERATOR.IS}
                  onChange={() => this.handleOPChanged(OPERATOR.IS)}
                />{" "}
                IS
              </label>

              <label htmlFor={`custom_isnot`} className="cursor-pointer">
                <input
                  id={`custom_isnot`}
                  type="radio"
                  name={`custom_targeting_radio`}
                  value={OPERATOR.IS_NOT}
                  checked={operator === OPERATOR.IS_NOT}
                  onChange={() => this.handleOPChanged(OPERATOR.IS_NOT)}
                />{" "}
                IS NOT
              </label>
            </div>
          </div>
          <div className="border-t mt-1 flex items-center justify-end px-2 py-2">
            <div className="text-red-600">{errorMsg}</div>
            <button
              type="button"
              className="mb-2 px-4 py-1 text-blue-700 hover:underline"
              onClick={this.handleCancelCustom}
            >
              Cancel
            </button>
            <button
              type="button"
              className="rounded shadow mb-2 bg-blue-200 px-4 py-1 font-semibold text-blue-700 hover:bg-blue-300"
              onClick={this.handleAddCustom}
            >
              Save Custom
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default CustomTargetingControlV2;
