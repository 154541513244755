import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import CSTConfigForm from "./CSTConfigForm";
import ModalWrapper from "components/common/ModalWrapper";
import { notify } from "react-notify-toast";
import KeyValueUsageEstimation from "./KeyValueUsageEstimation";
import NetworkEnsureResourcesButton from "../NetworkEnsureResources";
import OpenLinkInNewWindowBtn from "components/common/OpenLinkInNewWindowBtn";
import CSTConfigDiffView from "./CSTConfigDiffView";
import { transformToFinalConfig } from "./CstConfigHelper";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const DEFAULT_CONFIG = {
  general: {
    customDimensionEnabled: false,

    key: null,
    managedValues: [],
    benchmarkValues: [],

    amp_rtc: {
      enabled: false,
    },
  },
  inventory_integration: {
    gpt: {
      enable_optimization: false,
    },
    mobile_android: {
      enable_optimization: false,
    },
    mobile_ios: {
      enable_optimization: false,
    },
    video: {
      enable_optimization: false,
    },
    amp: {
      enable_optimization: false,
    },
  },
};

class NetworkCSTConfigViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      isGamInfoLoading: false,
      gamInfoErrMsg: null,
      gamInfo: null,

      networkId: null,
      networkInfo: null,
      errMsg: null,

      newConfig: null,
      finalConfig: null, // config after transform to save
      originalConfig: null,

      saveErrMsg: null,

      isConfigInvalid: false,

      isPreviewModalOpen: false,
      isShowMore: false,
    };

    this.handleConfigChanged = this.handleConfigChanged.bind(this);
    this.handleSaveCSTConfig = this.handleSaveCSTConfig.bind(this);
    this.handleOpenPreviewModal = this.handleOpenPreviewModal.bind(this);
    this.handleClosePreviewModal = this.handleClosePreviewModal.bind(this);
    this.handleConfigIsInvalid = this.handleConfigIsInvalid.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
    this.handleHideMore = this.handleHideMore.bind(this);
    this.getEstimation = this.getEstimation.bind(this);
    this.getRISuggestion = this.getRISuggestion.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }
      const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
      if (!networkInfo) {
        throw new Error("Invalid Network");
      }

      document.title = `${networkId} Network CST Config | YB Observer`;

      const { cstConfig } = await NetworkAPI.getCstConfig({ networkId });
      const newConfig = _.defaultsDeep(cstConfig, DEFAULT_CONFIG);
      const originalConfig = _.cloneDeep(newConfig);

      originalConfig.general.managedValues = _.sortBy(
        originalConfig.general.managedValues,
        "name"
      );
      originalConfig.general.benchmarkValues = _.sortBy(
        originalConfig.general.benchmarkValues,
        "name"
      );

      this.setState({
        networkId,
        networkInfo,

        newConfig,
        originalConfig,

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleConfigChanged(newConfig) {
    this.setState({ newConfig });
  }

  handleOpenPreviewModal() {
    const { newConfig } = this.state;
    const finalConfig = transformToFinalConfig({
      config: _.cloneDeep(newConfig),
    });

    this.setState({ isPreviewModalOpen: true, finalConfig });
  }

  handleClosePreviewModal() {
    this.setState({
      isPreviewModalOpen: false,
      saveErrMsg: null,
      finalConfig: null,
    });
  }

  handleConfigIsInvalid(isInvalid) {
    this.setState({ isConfigInvalid: isInvalid });
  }

  handleShowMore() {
    this.setState({ isShowMore: true });
  }

  handleHideMore() {
    this.setState({ isShowMore: false });
  }

  async getEstimation() {
    const { networkId, newConfig } = this.state;
    const estimateResource = await NetworkAPI.getCstEstimateResource({
      networkId,
      config: newConfig,
    });
    // console.log(estimateResource);
    return estimateResource;
  }

  async getRISuggestion(params) {
    const { networkId } = this.state;

    const r = await NetworkAPI.getRegisteredInventorySuggestion({
      networkId,
      params,
    });
    console.log(r);
    return r;
  }

  async handleSaveCSTConfig() {
    this.setState({ isSaving: true });
    try {
      const { networkId, finalConfig } = this.state;
      console.log("save config", finalConfig);
      const params = {
        networkId,
        config: finalConfig,
      };
      const r = await NetworkAPI.createCstConfig(params);
      console.log("save result", r);
      this.setState({
        isSaving: false,
        saveErrMsg: null,
      });

      notify.show(
        "CST Config saved! Automatically reloading page...",
        "success"
      );

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log("Failed to save cst config", err);
      this.setState({
        isSaving: false,
        saveErrMsg: err.toString(),
      });
    }
  }

  parseConfig = () => {
    const { newConfig } = this.state;
    const finalConfig = transformToFinalConfig({
      config: _.cloneDeep(newConfig),
    });

    return finalConfig;
  };

  render() {
    const {
      networkId,
      networkInfo,

      newConfig,
      finalConfig,
      originalConfig,

      isLoading,
      errMsg,
      isConfigInvalid,

      isSaving,
      saveErrMsg,
      isPreviewModalOpen,
      isShowMore,
    } = this.state;

    return (
      <div>
        {isLoading && <LoadingUI></LoadingUI>}
        {errMsg && <div className="text-red-800">{errMsg}</div>}

        {networkInfo && (
          <div>
            <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>
            <div className="min-h-screen bg-gray-200 px-12 py-8">
              <div className="flex items-center justify-between">
                <PageTitle title="Network CST Config"></PageTitle>
              </div>

              <div className="flex w-full">
                <div className="mt-2 w-3/4 pr-2">
                  <CSTConfigForm
                    networkId={networkId}
                    networkInfo={networkInfo}
                    config={newConfig}
                    handleConfigChanged={this.handleConfigChanged}
                    handleConfigIsInvalid={this.handleConfigIsInvalid}
                    getRISuggestion={this.getRISuggestion}
                  ></CSTConfigForm>
                </div>

                <div className="w-1/4 pl-2">
                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      className={`shadow rounded px-8 py-3 font-semibold ${
                        isConfigInvalid
                          ? "cursor-not-allowed bg-gray-400 text-white"
                          : "bg-blue-400 text-white hover:bg-blue-600"
                      }`}
                      onClick={this.handleOpenPreviewModal}
                      disabled={isConfigInvalid}
                    >
                      Preview <b>CST Config</b> and save
                    </button>
                  </div>
                  <div className="font-semibold text-red-600">{errMsg}</div>

                  {originalConfig && newConfig && (
                    <div
                      className="mt-4 overflow-y-auto"
                      style={{ maxHeight: "420px" }}
                    >
                      <CSTConfigDiffView
                        originalConfig={originalConfig}
                        newConfig={this.parseConfig()}
                        defaultConfig={{}}
                      ></CSTConfigDiffView>
                    </div>
                  )}

                  <div className="rounded mt-4 flex w-full bg-gray-300 p-2">
                    <span className="font-semibold">GAM Information</span>
                    <button
                      type="button"
                      className="border ml-auto cursor-pointer rounded-full border-gray-500 p-1 text-xs text-gray-900 hover:border-gray-700"
                      onClick={
                        isShowMore ? this.handleHideMore : this.handleShowMore
                      }
                    >
                      {isShowMore ? (
                        <IoIosArrowUp></IoIosArrowUp>
                      ) : (
                        <IoIosArrowDown></IoIosArrowDown>
                      )}
                    </button>
                  </div>

                  <div
                    className={`px-2 py-4 ${isShowMore ? "block" : "hidden"}`}
                  >
                    <KeyValueUsageEstimation
                      networkId={networkId}
                      getEstimation={this.getEstimation}
                    ></KeyValueUsageEstimation>
                  </div>

                  <div className="mt-4 flex items-center justify-center bg-gray-100 p-4">
                    <NetworkEnsureResourcesButton
                      networkId={networkId}
                    ></NetworkEnsureResourcesButton>
                  </div>
                </div>
              </div>

              {isPreviewModalOpen && (
                <ModalWrapper
                  isOpen={isPreviewModalOpen}
                  showCloseFooter={false}
                  handleClose={this.handleClosePreviewModal}
                  width="60%"
                >
                  <div>
                    <div className="mb-2 text-xl font-bold text-gray-700">
                      Preview CST Config
                    </div>

                    <div
                      className="border overflow-y-auto"
                      style={{ maxHeight: "320px" }}
                    >
                      <pre>{JSON.stringify(finalConfig, null, 2)}</pre>
                    </div>

                    <div className="rounded border mt-2 border-orange-300 bg-orange-100 px-4 py-2 text-orange-900">
                      <div className="font-semibold">
                        AMP related settings changed?
                      </div>
                      <div className="flex items-center gap-2 text-sm">
                        Remember to <b>"Sync Cloudflare"</b> in Code Snippet
                        page after saving!
                        <OpenLinkInNewWindowBtn
                          href={`${window.location.origin}/#/ops-mgmt/account/network/${networkId}/code-snippet`}
                        ></OpenLinkInNewWindowBtn>
                      </div>
                      <div className="text-sm">
                        (No need to "Create New Build", syncing Cloudflare will
                        create new build automatically)
                      </div>
                    </div>

                    <div className="mt-4 flex flex-row-reverse items-center">
                      <div>
                        <button
                          type="button"
                          className="rounded shadow bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-700"
                          onClick={this.handleSaveCSTConfig}
                        >
                          {isSaving ? "Saving..." : "Confirm save"}
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          disabled={isSaving}
                          className={`px-4 py-2 text-blue-700 ${
                            isSaving ? "cursor-not-allowed" : ""
                          }`}
                          onClick={this.handleClosePreviewModal}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="text-red-600">{saveErrMsg}</div>
                    </div>
                  </div>
                </ModalWrapper>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default NetworkCSTConfigViewer;
