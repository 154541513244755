import _ from "lodash";
import { api, fetchRespJsonWithoutCredentials, postData } from "./base";

export default {
  getNetworkInventoryAnalysisReports,
  getAbTestingVerificationReports,
  getCodeSnippetIntegrationVerificationReports,
};

// params: { networkId, startDate, endDate }
// optional: forceReload, extGamUnitIds
async function getNetworkInventoryAnalysisReports(params) {
  const apiUrl = `${api}/analysis-reports/network-inventory-analysis`;
  const { url } = await postData(apiUrl, params);
  if (!url) {
    throw new Error("No data in Network Inventory Analysis Reports.");
  }
  const data = await fetchRespJsonWithoutCredentials(url);
  return data;
}

// params: { networkId, startDate, endDate }
// optional: forceReload, extGamUnitIds
async function getAbTestingVerificationReports(params) {
  const apiUrl = `${api}/analysis-reports/ab-testing-verification-reports`;
  const { url } = await postData(apiUrl, params);
  if (!url) {
    throw new Error("No data in AB Testing Verification Reports.");
  }

  const data = await fetchRespJsonWithoutCredentials(url);
  return data;
}

// params: { networkId, startDate, endDate }
// optional: forceReload, extGamUnitIds
async function getCodeSnippetIntegrationVerificationReports(params) {
  const apiUrl = `${api}/analysis-reports/code-snippet-verification-reports`;
  const { url } = await postData(apiUrl, params);
  if (!url) {
    throw new Error(
      "No data in Code Snippet Integration Verification Reports."
    );
  }

  const data = await fetchRespJsonWithoutCredentials(url);
  return data;
}
