import React from "react";
import _ from "lodash";
import PopupWrapper from "../../../common/PopupWrapper";
import UnitsSelector from "components/common/UnitsSelector";

class VPDetailPopover extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { networkId, gamUnitIds, triggerElement } = this.props;

    return (
      <PopupWrapper
        place="bottom"
        triggerType="click"
        hideArrow={false}
        triggerElement={triggerElement}
        popupElement={
          <div className="shadow border p-4" style={{ width: "600px" }}>
            <div className="border-b">
              <div className="mt-2 font-semibold text-gray-900">
                Units ({gamUnitIds.length})
              </div>
              <div>
                {gamUnitIds ? (
                  <UnitsSelector
                    networkId={networkId}
                    disabled={true}
                    selectedItems={gamUnitIds}
                    selectMetric="unitId"
                    isCreatable={true}
                  ></UnitsSelector>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        }
      ></PopupWrapper>
    );
  }
}

export default VPDetailPopover;
