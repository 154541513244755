import _ from "lodash";

import { api, fetchRespJson, postData } from "apis/base";

export default {
  getUnitInfo,
  getUnitDetail,
  searchUnits,
  getUnitTags,
  getUnitFullpathHashList,
};

async function getUnitFullpathHashList({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/units-fullpath-hash`;
  return await fetchRespJson(apiUrl);
}

async function getUnitTags({ unitId }) {
  let apiUrl = `${api}/unit/${unitId}/tags`;
  const { tags } = await fetchRespJson(apiUrl);
  return tags;
}

async function searchUnits(params) {
  let apiUrl = `${api}/network/${params.networkId}/units-search`;
  return await postData(apiUrl, params);
}

async function getUnitDetail({ unitId }) {
  let apiUrl = `${api}/unit/${unitId}/detail-info`;
  // unit tags included in this api
  const unitInfo = await fetchRespJson(apiUrl);
  return unitInfo;
}

async function getUnitInfo({ unitId }) {
  let apiUrl = `${api}/unit/${unitId}/info`;
  const unitInfo = await fetchRespJson(apiUrl);
  unitInfo.billableDemandTypes = JSON.parse(unitInfo.billableDemandTypes);
  return unitInfo;
}
