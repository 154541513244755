import React from "react";
import _ from "lodash";
import CustomTargetingKeysSelector from "components/ops-mgmt/account/cst-config/CustomTargetingKeysSelector";
import CustomTargetingValuesSelector from "components/ops-mgmt/account/cst-config/CustomTargetingValuesSelector";
import CustomTargetingControlV2 from "./CustomTargetingControlV2";

import { NetworkAPI } from "apis";
import LoadingUI from "components/common/LoadingUI";

let cache = {};

class CustomSetTargetingFormatterV2 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isShowDetail: true, // users want to view at once
      isLoading: false,

      currentSet: {},
      originalSet: {},

      currentSetIndex: null,
      currentIndex: null,
      isEditCardOpen: false,

      customTargetingKeys: null,
    };

    this.asyncFn = null;

    this.onAddClicked = this.onAddClicked.bind(this);
    this.onShowDetailClicked = this.onShowDetailClicked.bind(this);

    this.handleAddNewSet = this.handleAddNewSet.bind(this);
    this.handleCancelNewSet = this.handleCancelNewSet.bind(this);
    this.handleEditSet = this.handleEditSet.bind(this);
  }

  async componentDidMount() {
    const { networkId } = this.props;
    if (!networkId) {
      return;
    }

    this.asyncFn = await this.queryCustomTargetingKeys();
  }

  async queryCustomTargetingKeys() {
    const { networkId } = this.props;

    this.setState({ isLoading: true });
    try {
      let items = [];
      if (cache[networkId]) {
        items = cache[networkId];
      } else {
        const r = await NetworkAPI.getCustomTargetingKeys({ networkId });
        items = r.items;
        cache[networkId] = items;
      }

      this.setState({
        customTargetingKeys: items,
        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        customTargetingKeys: null,
        isLoading: false,
        errMsg: `Error query custom targeting keys: ${err.toString()}`,
      });
    }
  }

  onShowDetailClicked() {
    this.setState({ isShowDetail: !this.state.isShowDetail });
  }

  onAddClicked({ outerIndex }) {
    this.setState({ currentSetIndex: outerIndex, isEditCardOpen: true });
    this.props.handleIsEditing(true);
  }

  handleAddNewSet({ newSet, outerIndex, index }) {
    if (index !== null && index !== undefined) {
      this.props.handleEditSet({ newSet, outerIndex, index });
    } else {
      this.props.handleAddSet({ newSet, outerIndex, index });
    }

    this.setState({
      currentSet: [],
      originalSet: [],
      currentSetIndex: null,
      currentIndex: null,
      isEditCardOpen: false,
    });
    this.props.handleIsEditing(false);
  }

  handleCancelNewSet() {
    this.setState({
      currentSet: [],
      originalSet: [],
      currentSetIndex: null,
      isEditCardOpen: false,
    });
    this.props.handleIsEditing(false);
  }

  handleEditSet({ outerIndex, index, custom }) {
    this.setState({
      currentSetIndex: outerIndex,
      currentIndex: index,
      currentSet: custom,
      originalSet: _.cloneDeep(custom),
      isEditCardOpen: true,
    });
    this.props.handleIsEditing(true);
  }

  render() {
    const {
      networkId,
      setsOfCustom,
      showActionButtons,
      handleRemoveSet,
      isActionButtonsDisabled,
      includeActionButtons = false,
    } = this.props;
    const {
      isShowDetail,
      isEditCardOpen,
      currentSetIndex,
      currentIndex,
      currentSet,

      isLoading, // loading query targeting keys
      customTargetingKeys,
    } = this.state;

    if (!setsOfCustom || !_.isArray(setsOfCustom)) {
      return "None";
    }

    return (
      <div>
        <div className="text-right">
          <span
            className="cursor-pointer text-xs text-blue-600 hover:text-blue-800 hover:underline"
            onClick={this.onShowDetailClicked}
          >
            {isShowDetail ? "Hide" : "Show"} detail
          </span>
        </div>

        {isLoading && <LoadingUI></LoadingUI>}

        {customTargetingKeys &&
          setsOfCustom.map((customs, i) => {
            return (
              <div key={i}>
                {customs.map((custom, index) => {
                  const { keyId } = custom;

                  return (
                    <div
                      className="flex items-center justify-between border-l-2 border-blue-600"
                      key={keyId}
                    >
                      {currentSetIndex === i &&
                      currentIndex === index &&
                      isEditCardOpen ? (
                        <div className="w-full">
                          <CustomTargetingControlV2
                            networkId={networkId}
                            customValue={currentSet}
                            handleAddNewSet={({ newSet }) =>
                              this.handleAddNewSet({
                                newSet,
                                outerIndex: i,
                                index,
                              })
                            }
                            handleCancelNewSet={this.handleCancelNewSet}
                          ></CustomTargetingControlV2>
                        </div>
                      ) : (
                        <CustomFormatter
                          outerIndex={i}
                          index={index}
                          custom={custom}
                          networkId={networkId}
                          showActionButtons={showActionButtons}
                          handleRemoveSet={handleRemoveSet}
                          handleEditSet={this.handleEditSet}
                          isShowDetail={isShowDetail}
                          customTargetingKeys={customTargetingKeys}
                        ></CustomFormatter>
                      )}

                      {includeActionButtons && (
                        <div style={{ width: "56px" }}>
                          {index === customs.length - 1 && (
                            <button
                              type="button"
                              className={`rounded shadow ${
                                isEditCardOpen || isActionButtonsDisabled
                                  ? "cursor-not-allowed bg-gray-400 text-gray-800"
                                  : "bg-blue-500 text-white hover:bg-blue-600"
                              }  px-2 py-1`}
                              onClick={() =>
                                this.onAddClicked({ outerIndex: i, index })
                              }
                              disabled={
                                isEditCardOpen || isActionButtonsDisabled
                              }
                            >
                              AND
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}

                {currentSetIndex === i &&
                  currentIndex === null &&
                  isEditCardOpen && (
                    <CustomTargetingControlV2
                      networkId={networkId}
                      customValue={currentSet}
                      handleAddNewSet={({ newSet }) =>
                        this.handleAddNewSet({ newSet, outerIndex: i })
                      }
                      handleCancelNewSet={this.handleCancelNewSet}
                    ></CustomTargetingControlV2>
                  )}

                {i < setsOfCustom.length - 1 && (
                  <div className="w-full bg-blue-100 p-1 text-center font-semibold text-blue-800">
                    OR
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}

function CustomFormatter(props) {
  const {
    custom,
    outerIndex, // new
    index,
    networkId,
    showActionButtons,
    handleRemoveSet,
    handleEditSet,
    isShowDetail,
    customTargetingKeys,
  } = props;
  const { keyId, valueIds, operator } = custom;
  const valueIdsString = valueIds ? valueIds.join(", ") : "";
  const targetKey = _.find(customTargetingKeys, { id: _.toInteger(keyId) });
  const keyName = targetKey ? targetKey.name : "";

  return (
    <div className="border rounded w-full bg-white p-1 text-gray-700">
      <div className="float-right">
        Operator:{" "}
        <span
          className={`rounded px-2 text-sm font-semibold text-white ${
            operator === "IS" ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {operator}
        </span>
      </div>

      <div>
        Key ID: <span className="font-semibold">{keyId}</span>
        {isShowDetail && (
          <CustomTargetingKeysSelector
            customTargetingKeys={customTargetingKeys}
            isReadonly={true}
            networkId={networkId}
            keyId={keyId}
          ></CustomTargetingKeysSelector>
        )}
      </div>
      <div>
        Value IDs ({valueIds ? valueIds.length : 0}):{" "}
        {isShowDetail ? (
          <>
            {/* <div
              className="font-semibold"
              style={{ maxHeight: "55px", overflow: "scroll" }}
            >
              {valueIdsString}
            </div> */}

            <CustomTargetingValuesSelector
              isReadonly={true}
              networkId={networkId}
              keyId={keyId}
              keyName={keyName}
              isMulti={true}
              managedValueIds={valueIds}
            ></CustomTargetingValuesSelector>
          </>
        ) : (
          <span>{valueIdsString}</span>
        )}
      </div>

      {!showActionButtons ? (
        ""
      ) : (
        <div>
          <button
            type="button"
            className="rounded mr-2 bg-blue-100 px-2 text-blue-600 hover:bg-blue-200"
            onClick={() => handleEditSet({ index, outerIndex, custom })}
          >
            Edit
          </button>
          <button
            type="button"
            className="rounded bg-red-100 px-2 text-red-600 hover:bg-red-200"
            onClick={() => handleRemoveSet({ index, outerIndex })}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
}

export default CustomSetTargetingFormatterV2;
