import _ from "lodash";
import { api, fetchRespJson, postData } from "./base";

export default {
  getApps,
  getNetworkAcConfigs,
  getPublisherRawAppConfigs,
  getNetworkRawAppConfigs,
  getAppSchemaAndDefaultValues,
  createOrUpdatePublisherAppConfig,
  createOrUpdateNetworkAppConfig,
  createOrUpdateAppSchema,
  createOrUpdateAppDefaultValues,
};

async function getApps() {
  const apiUrl = `${api}/app-configurator/apps`;
  return await fetchRespJson(apiUrl);
}

async function getNetworkAcConfigs({ networkId, appNames }) {
  let queryString = "";
  if (appNames) {
    queryString = `?appNames=${appNames.join(",")}`;
  }
  const apiUrl = `${api}/app-configurator/network/${networkId}/configs${queryString}`;
  return await fetchRespJson(apiUrl);
}

async function getPublisherRawAppConfigs({ pubId, appNames }) {
  let queryString = "";
  if (appNames) {
    queryString = `?appNames=${appNames.join(",")}`;
  }
  const apiUrl = `${api}/app-configurator/publisher/${pubId}/raw-configs${queryString}`;
  return await fetchRespJson(apiUrl);
}

async function getNetworkRawAppConfigs({ networkId, appNames }) {
  let queryString = "";
  if (appNames) {
    queryString = `?appNames=${appNames.join(",")}`;
  }
  const apiUrl = `${api}/app-configurator/network/${networkId}/raw-configs${queryString}`;
  return await fetchRespJson(apiUrl);
}

async function getAppSchemaAndDefaultValues({ appName }) {
  const apiUrl = `${api}/app-configurator/${appName}/config-schema`;
  return await fetchRespJson(apiUrl);
}

// params: { appName, config }
async function createOrUpdatePublisherAppConfig({ pubId, params }) {
  const apiUrl = `${api}/app-configurator/publisher/${pubId}/raw-config`;
  return await postData(apiUrl, params);
}

// params: { appName, config }
async function createOrUpdateNetworkAppConfig({ networkId, params }) {
  const apiUrl = `${api}/app-configurator/network/${networkId}/raw-config`;
  return await postData(apiUrl, params);
}

// params: { appName, configSchema }
async function createOrUpdateAppSchema(params) {
  const apiUrl = `${api}/app-configurator/create-update-config-schema`;
  return await postData(apiUrl, params);
}

// params: { appName, defaultValues }
async function createOrUpdateAppDefaultValues(params) {
  const apiUrl = `${api}/app-configurator/create-update-default-values`;
  return await postData(apiUrl, params);
}
