import React from "react";
import _ from "lodash";
import { AppConfiguratorAPI, NetworkAPI } from "apis";
import { notify } from "react-notify-toast";

import NetworkInfoHeader from "components/ops-mgmt/common/NetworkInfoHeader";
import PublisherInfoHeader from "components/ops-mgmt/account/network-features/PublisherInfoHeader";
import LoadingUI from "components/common/LoadingUI";
import TextAreaWithJsonCheck from "components/common/TextAreaWithJsonCheck";
import ModalWrapper from "components/common/ModalWrapper";

const VIEWER = {
  NETWORK: "network",
  PUBLISHER: "publisher",
};

class AcRawConfigViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSaving: false,
      isDisableSave: true,
      isOpenModal: false,
      viewer: null,

      apps: [],
      rawConfigs: [],
      networkInfo: null,
      pubId: null,
      selectedConfig: "",
      selectedApp: null,
    };

    this.tempSelected = {
      config: "",
      app: null,
    };

    this.handleConfigSelect = this.handleConfigSelect.bind(this);
    this.handleConfigChanged = this.handleConfigChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handelModalConfirm = this.handelModalConfirm.bind(this);
    this.handelModalCancel = this.handelModalCancel.bind(this);
  }

  async componentDidMount() {
    const { publisherId, networkId } = this.props.match.params;

    if (publisherId) {
      document.title = `${publisherId} Publisher AC Raw Config | YB Observer`;
    }
    if (networkId) {
      document.title = `${networkId} Network AC Raw Config | YB Observer`;
    }

    this.setState({ isLoading: true });
    const apps = await AppConfiguratorAPI.getApps();
    if (apps) this.setState({ apps });

    // Set profile and rawConfigs
    if (publisherId) {
      await this.preparePublisherData(publisherId);
      this.setState({ viewer: VIEWER.PUBLISHER });
    } else if (networkId) {
      await this.prepareNetworkData(networkId);
      this.setState({ viewer: VIEWER.NETWORK });
    }
    this.setState({ isLoading: false });
  }

  async preparePublisherData(publisherId) {
    const rawConfigs = await AppConfiguratorAPI.getPublisherRawAppConfigs({
      pubId: publisherId,
    });
    if (rawConfigs) this.setState({ rawConfigs });
    this.setState({ pubId: publisherId });
  }

  async prepareNetworkData(networkId) {
    const rawConfigs = await AppConfiguratorAPI.getNetworkRawAppConfigs({
      networkId,
    });
    if (rawConfigs) this.setState({ rawConfigs });

    const network = await NetworkAPI.getNetworkInfo({
      networkId,
    });
    if (network) this.setState({ networkInfo: network });
  }

  handleConfigSelect({ app, config }) {
    const { isDisableSave, selectedApp } = this.state;
    if (selectedApp === app) return;

    const configStr = config ? JSON.stringify(config, null, 4) : "{}";
    if (!isDisableSave) {
      this.tempSelected.config = configStr;
      this.tempSelected.app = app;
      this.setState({ isOpenModal: true });
    } else {
      this.setState({
        selectedConfig: configStr,
        selectedApp: app,
        isDisableSave: true,
      });
    }
  }

  handleConfigChanged(input) {
    try {
      this.setState({ selectedConfig: input });
      const config = JSON.parse(input);
      const app = _.find(this.state.rawConfigs, {
        app_name: this.state.selectedApp,
      });
      const appConfig = app ? app.config : {};
      this.setState({ isDisableSave: _.isEqual(appConfig, config) });
    } catch (e) {
      this.setState({ isDisableSave: true });
    }
  }

  handelModalConfirm() {
    this.setState({
      selectedConfig: this.tempSelected.config,
      selectedApp: this.tempSelected.app,
      isDisableSave: true,
      isOpenModal: false,
    });
  }

  handelModalCancel() {
    this.setState({ isOpenModal: false });
  }

  async handleSave() {
    const {
      selectedApp,
      selectedConfig,
      viewer,
      rawConfigs,
      pubId,
      networkInfo,
    } = this.state;
    if (!selectedApp) return;

    const config = JSON.parse(selectedConfig);
    if (!config) return;

    this.setState({ isSaving: true });
    const params = {
      appName: selectedApp,
      config,
    };

    try {
      const result =
        viewer === VIEWER.PUBLISHER
          ? await AppConfiguratorAPI.createOrUpdatePublisherAppConfig({
              pubId,
              params,
            })
          : await AppConfiguratorAPI.createOrUpdateNetworkAppConfig({
              networkId: networkInfo.networkId,
              params,
            });
      if (result && result.op === "ok") {
        notify.show("Raw config saved!", "success");
        const newRawConfigs = _.cloneDeep(rawConfigs);
        const idx = _.findIndex(newRawConfigs, { app_name: selectedApp });
        if (idx >= 0) {
          newRawConfigs[idx].config = config;
        } else {
          newRawConfigs.push({ app_name: selectedApp, config });
        }
        this.setState({ rawConfigs: newRawConfigs, isDisableSave: true });
      } else notify.show("Failed to save raw config", "error");
    } catch (e) {
      notify.show("Failed to save raw config", "error");
    }

    this.setState({ isSaving: false });
  }

  render() {
    const {
      isLoading,
      isDisableSave,
      isSaving,
      isOpenModal,
      viewer,

      networkInfo,
      pubId,
      rawConfigs,
      apps,

      selectedConfig,
      selectedApp,
    } = this.state;

    const titleClass = "text-lg font-semibold text-gray-800";
    const headerClass = "text-ml font-medium text-gray-600";

    const appFilter = apps.filter((app) => {
      return !_.find(rawConfigs, { app_name: app.name });
    });

    const rawConfigsParse = rawConfigs.map((config) => {
      const app = _.find(apps, { name: config.app_name });
      return {
        ...config,
        status: app ? app.status : "Unknown",
      };
    });

    return (
      <>
        {isLoading && <LoadingUI></LoadingUI>}
        {viewer === VIEWER.NETWORK && (
          <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>
        )}
        {viewer === VIEWER.PUBLISHER && (
          <PublisherInfoHeader pubId={pubId}></PublisherInfoHeader>
        )}
        {!isLoading && rawConfigs && (
          <div className="bg-white px-12 py-8">
            <div className="text-3xl font-extrabold">
              {`${_.startCase(viewer)} App Configuration Raw Config`}
            </div>
            <div className="mb-4">
              <label className={titleClass}>1. Select App</label>
              <div className="mb-2 px-4">
                <label className={headerClass}>Has Config:</label>
                <div className="flex flex-wrap gap-2">
                  {rawConfigsParse.map((item) => (
                    <div
                      key={item.app_name}
                      className={`cursor-pointer rounded-md px-3 py-1 ${
                        selectedApp === item.app_name
                          ? "bg-blue-400 text-gray-100"
                          : "bg-blue-100 text-black hover:bg-blue-200"
                      }`}
                      onClick={() =>
                        this.handleConfigSelect({
                          app: item.app_name,
                          config: item.config,
                        })
                      }
                    >
                      {_.startCase(item.app_name)}
                    </div>
                  ))}
                </div>
              </div>

              <div className="px-4">
                <label className={headerClass}>No Config:</label>
                <div className="flex flex-wrap gap-2">
                  {appFilter.map((app) => (
                    <div
                      key={app.name}
                      className={`cursor-pointer rounded-md  px-3 py-1  ${
                        selectedApp === app.name
                          ? "bg-gray-500 text-white"
                          : "bg-gray-200 text-black hover:bg-gray-300"
                      }`}
                      onClick={() => this.handleConfigSelect({ app: app.name })}
                    >
                      {_.startCase(app.name)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <label className={titleClass}>
                2. Edit Raw Config
                {selectedApp ? ` - ${_.startCase(selectedApp)}` : ""}
              </label>
              <div className="relative px-4 py-2">
                <TextAreaWithJsonCheck
                  rows={15}
                  inputValue={selectedConfig}
                  handleInputChanged={this.handleConfigChanged}
                ></TextAreaWithJsonCheck>
                <button
                  type="button"
                  className={`shadow rounded absolute  px-8 py-2 font-semibold text-white  ${
                    isDisableSave || isSaving
                      ? "cursor-not-allowed bg-blue-200"
                      : "bg-blue-400 hover:bg-blue-600"
                  }`}
                  style={{ top: "24.5rem", right: "1rem" }}
                  disabled={isDisableSave || isSaving}
                  onClick={this.handleSave}
                >
                  Save
                </button>
                {!selectedApp && (
                  <div className="absolute left-0 top-0 h-full w-full bg-gray-400 opacity-50"></div>
                )}
              </div>
            </div>

            <ConfirmChangeAppModal
              isOpenModal={isOpenModal}
              handleConfirm={this.handelModalConfirm}
              handleCancel={this.handelModalCancel}
            ></ConfirmChangeAppModal>
          </div>
        )}
      </>
    );
  }
}

export default AcRawConfigViewer;

class ConfirmChangeAppModal extends React.PureComponent {
  render() {
    const { isOpenModal, handleConfirm, handleCancel } = this.props;

    return (
      <ModalWrapper isOpen={isOpenModal} showCloseFooter={false} width="30%">
        <div>
          <div className="mb-2 text-xl font-bold text-gray-700">
            Unsaved Changes Alert
          </div>

          <div className="mb-4 text-gray-600">
            You have unsaved changes. If you switch now, these changes will be
            lost. <br />
            Do you want to proceed?
          </div>

          <div className="flex items-center justify-end gap-2">
            <button
              type="button"
              className="rounded shadow bg-gray-400 px-8 py-2 font-semibold text-white hover:bg-gray-500"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="shadow rounded bg-blue-400 px-8 py-2 font-semibold text-white hover:bg-blue-600"
              onClick={handleConfirm}
            >
              Proceed
            </button>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}
