import React from "react";
import _ from "lodash";
import ArrayTextAreaControl from "./ArrayTextAreaControl";

const CST_NONBILLABLE_SETTING_TYPE = [
  {
    value: "ADVERTISER",
    label: "Advertiser",
  },
  {
    value: "ORDER",
    label: "Order",
  },
];

// Used in Features
class CstNonbillableSettingControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { type = CST_NONBILLABLE_SETTING_TYPE[0].value, ids = [] } =
      this.props.inputValue || {};
    let errorMsg = null;

    this.state = {
      type,
      ids,
      errorMsg,
    };

    this.handleIdsChanged = this.handleIdsChanged.bind(this);
    this.handleCstNonbillableTypeChanged =
      this.handleCstNonbillableTypeChanged.bind(this);
  }

  handleIdsChanged(ids) {
    this.setState({ ids });

    const cstNonbillableObject = {
      type: this.state.type,
      ids: _.map(ids, (id) => parseInt(id)),
    };
    this.props.handleNewValueChanged(cstNonbillableObject);
  }

  handleCstNonbillableTypeChanged(e) {
    const type = e.target.value;
    this.setState({ type });

    const cstNonbillableObject = {
      type,
      ids: this.state.ids,
    };
    this.props.handleNewValueChanged(cstNonbillableObject);
  }

  render() {
    const { type, ids, errorMsg } = this.state;
    const { valueSchema, inputValue, handleNewValueChanged, handleHasError } =
      this.props;

    return (
      <div className="border rounded ml-4 bg-white p-2 text-sm text-gray-700">
        <>
          <div className="font-semibold">Type:</div>

          <div className="flex gap-4">
            <CstNonbillableSettingTypeControl
              value={type}
              handleChange={this.handleCstNonbillableTypeChanged}
            ></CstNonbillableSettingTypeControl>
          </div>

          <div className="mt-2 font-semibold">{type} IDs:</div>
          <div className="my-1">
            <ArrayTextAreaControl
              valueSchema={"array_integer"}
              inputValue={ids}
              handleNewValueChanged={this.handleIdsChanged}
              handleHasError={handleHasError}
            ></ArrayTextAreaControl>
          </div>

          <div className="text-red-600">{errorMsg}</div>
        </>
      </div>
    );
  }
}

function CstNonbillableSettingTypeControl({ value, handleChange }) {
  const options = CST_NONBILLABLE_SETTING_TYPE;

  return (
    <div>
      <select
        className="border rounded px-1"
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default CstNonbillableSettingControl;
