import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import MaxCurrentView from "components/snapshot/MaxCurrentView";
import NumberFormat from "components/common/NumberFormat";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import ForceRefreshButton from "components/common/ForceRefreshButton";

class KeyValueUsageEstimation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      reportableKeyValues: null,

      // estimation
      usageErrMsg: null,
      isEstimationLoading: false,
      reportableCount: null,
      reportableEstimate: null,
      reportableUsed: null,
      used: null,
    };

    this.getEstimation = this.getEstimation.bind(this);
    this.handleForceRefreshGamInfo = this.handleForceRefreshGamInfo.bind(this);
  }

  async componentDidMount() {
    await this.queryNetworkGamInfo({ isForceRefresh: false });
    await this.getEstimation();
  }

  async handleForceRefreshGamInfo() {
    await this.queryNetworkGamInfo({ isForceRefresh: true });
  }

  async queryNetworkGamInfo({ isForceRefresh = false }) {
    this.setState({
      isLoading: true,
    });
    try {
      const { networkId } = this.props;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }

      const { updatedAt, gamInfoResponse } = await NetworkAPI.getNetworkGamInfo(
        { networkId, isForceRefresh }
      );
      if (gamInfoResponse.status !== "OK") {
        throw new Error(gamInfoResponse.error);
      }

      // {max: xxx, current: xxx}
      const reportableKeyValues = _.get(gamInfoResponse, [
        "results",
        "limits",
        "reporting",
        "reportableKeyValues",
      ]);

      this.setState({
        gamInfoUpdateTime: updatedAt,
        reportableKeyValues,

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  async getEstimation() {
    this.setState({
      isEstimationLoading: true,
    });
    try {
      const { usage, resources } = await this.props.getEstimation();
      const stats = _.get(JSON.parse(resources), "stats");

      const key20Used = _.get(
        stats,
        ["customTargetingValues", "reportable", "in2w_key20"],
        0
      );

      // Important! key16 estimate is not accurate. Don't show for now
      // key20 reportable
      const key20 = _.find(usage, { key: "in2w_key20" });

      const reportableEstimate = key20.count;
      const reportableCount = key20.count - key20Used;

      this.setState({
        reportableCount,
        reportableEstimate,
        reportableUsed: key20Used,

        isEstimationLoading: false,
        usageErrMsg: null,
      });
    } catch (err) {
      this.setState({
        isEstimationLoading: false,
        usageErrMsg: err.toString(),
      });
    }
  }

  render() {
    const {
      isLoading,
      errMsg,
      isEstimationLoading,
      usageErrMsg,
      gamInfoUpdateTime,

      reportableKeyValues,
      reportableCount,
      reportableEstimate,
      reportableUsed,
    } = this.state;

    if (isLoading) return <LoadingUI></LoadingUI>;

    return (
      <div>
        {gamInfoUpdateTime && (
          <div className="flex items-center gap-1">
            <div className="text-xs text-gray-600">
              GAM Info last updated:{" "}
              <DateTimeFormatter
                datetime={gamInfoUpdateTime}
              ></DateTimeFormatter>
            </div>
            <ForceRefreshButton
              handleRefresh={this.handleForceRefreshGamInfo}
            ></ForceRefreshButton>
          </div>
        )}
        <div>
          <div className="text-lg font-semibold text-gray-800">
            Current key-value usage:
          </div>
          {errMsg ? (
            <div
              className="text-red-800"
              style={{ maxHeight: "300px", overflow: "auto" }}
            >
              {errMsg}
            </div>
          ) : (
            <>
              <div>
                {reportableKeyValues && (
                  <MaxCurrentView
                    title="Reportable key values"
                    max={reportableKeyValues.max}
                    current={reportableKeyValues.current}
                  ></MaxCurrentView>
                )}
              </div>
            </>
          )}
        </div>

        <div className="border-t py-4">
          <div className="text-lg font-semibold text-gray-800">
            Estimated key-value usage:
          </div>
          {isEstimationLoading ? (
            <LoadingUI></LoadingUI>
          ) : (
            <>
              {usageErrMsg ? (
                <div className="text-red-800">{usageErrMsg}</div>
              ) : (
                <>
                  <div>
                    {reportableKeyValues && (
                      <MaxCurrentView
                        title="Reportable key values"
                        max={reportableKeyValues.max}
                        current={reportableKeyValues.current}
                        diff={reportableCount}
                        isEstimate={true}
                      ></MaxCurrentView>
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {!isEstimationLoading && (
            <div className="border rounded p-2">
              <div className="text-gray-800">in2w_key20 (reportable):</div>

              <div>
                <div className="mb-2">
                  <div className="text-sm text-gray-700">
                    Estimation:{" "}
                    <NumberFormat
                      value={reportableEstimate}
                      format={"0,0"}
                    ></NumberFormat>
                  </div>
                  <div className="text-sm text-gray-700">
                    Currently using:{" "}
                    <NumberFormat
                      value={reportableUsed}
                      format={"0,0"}
                    ></NumberFormat>
                  </div>
                </div>
                <button
                  type="button"
                  className="border rounded shadow bg-white px-2 py-1 hover:bg-gray-100"
                  onClick={this.getEstimation}
                >
                  Estimate with current config settings
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default KeyValueUsageEstimation;
