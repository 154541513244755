import Highcharts from "highcharts";
import _ from "lodash";
import { formatThousands } from "./DataHelper";

const LINE_CHART_OPTION = {
  chart: {
    height: 240,
  },
  title: {
    text: null,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: "",
    },
  },
  legend: {
    verticalAlign: "top",
  },
  series: [],
};

const BAR_CHART_OPTION = {
  chart: {
    type: "bar",
    height: 240,
  },
  title: {
    text: null,
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: "Request",
    },
  },
  legend: {
    verticalAlign: "top",
    reversed: true,
  },
  series: [],
};

const COLUMN_CHART_OPTION = {
  chart: {
    type: "column",
    height: 240,
  },
  title: {
    text: null,
  },
  plotOptions: {
    series: {
      stacking: "normal",
    },
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: "",
    },
  },
  legend: {
    verticalAlign: "top",
  },
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
    formatter() {
      return _tooltipFormatter({
        chart: this,
        valuePostfix: "",
        includeTotal: true,
        includePercentage: true,
      });
    },
  },
  series: [],
};

const PIE_CHART_OPTION = {
  chart: {
    type: "pie",
    height: 240,
  },
  title: {
    text: null,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        format: "{point.percentage:.1f}%",
        distance: "-35%",
        filter: {
          property: "percentage",
          operator: ">",
          value: 5,
        },
      },
      showInLegend: true,
    },
  },
  legend: {
    align: "right",
    verticalAlign: "middle",
    layout: "vertical",
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
    formatter: function () {
      return `<span style="color:${this.color}">\u25CF</span> ${
        this.key
      }: <b>${this.percentage.toFixed(1)}%</b> (${formatThousands(this.y)})`;
    },
  },
  series: [],
};

const GAUGE_OPTION = {
  chart: {
    type: "solidgauge",
    height: "240",
    spacing: [0, 0, 0, 0],
    margin: [0, 0, 0, 0],
  },
  title: {
    text: null,
  },
  pane: {
    size: "140%",
    center: ["50%", "85%"],
    startAngle: -90,
    endAngle: 90,
    background: {
      backgroundColor: "#EEE",
      innerRadius: "60%",
      outerRadius: "100%",
      shape: "arc",
    },
  },
  yAxis: {
    min: 0,
    max: 100,
    stops: [
      [0.8, "#DF5353"],
      [1, "#55BF3B"],
    ],
    minorTickInterval: null,
    tickAmount: 2,
    labels: {
      y: 16,
    },
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        y: 0,
        borderWidth: 0,
        useHTML: true,
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  series: [],
};

export function _parseTrendOptions({ data, monthList, title }, option = {}) {
  return {
    ...COLUMN_CHART_OPTION,
    ...option,
    xAxis: {
      categories: monthList,
    },
    yAxis: {
      min: 0,
      title: {
        text: title,
      },
    },
    series: data,
  };
}

export function _parsePercentOptions({ data }, option = {}) {
  return {
    ...PIE_CHART_OPTION,
    ...option,
    series: [
      {
        data: data.map((item) => ({
          name: item.name,
          y: item.y || item.data,
          color: item.color,
        })),
      },
    ],
  };
}

export function _parseGaugeOptions({ data, limit = 1 }, option = {}) {
  const stops =
    limit < 1
      ? [
          [limit, "#DF5353"],
          [1, "#55BF3B"],
        ]
      : [[1, "#7cb5ec"]];
  return {
    ...GAUGE_OPTION,
    series: [
      {
        data: [data],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size: 2rem">{y}%</span><br/>' +
            "</div>",
        },
      },
    ],
    yAxis: {
      min: 0,
      max: 100,
      stops,
      minorTickInterval: null,
      tickAmount: 2,
      labels: {
        y: 16,
      },
    },
  };
}

export function _parseBarOptions(
  { data, categories, title, unit = "" },
  option = {}
) {
  return {
    ...BAR_CHART_OPTION,
    ...option,
    xAxis: {
      categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: title,
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return `<span>${formatThousands(this.y)}${unit}</span>`;
          },
        },
      },
    },
    series: data,
  };
}

export function _parseLineOptions(
  { data, monthList, title, unit = "" },
  option = {}
) {
  return {
    ...LINE_CHART_OPTION,
    ...option,
    xAxis: {
      categories: monthList,
    },
    yAxis: {
      min: 0,
      title: {
        text: title,
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
      formatter() {
        return _tooltipFormatter({
          chart: this,
          valuePostfix: unit,
          includeTotal: false,
          includePercentage: false,
        });
      },
    },
    series: data,
  };
}

export function _tooltipFormatter({
  chart,
  valuePrefix = "",
  valuePostfix = "",
  valueDecimalPoint = 0,
  includeTotal = false,
  includePercentage = false,
}) {
  return `
    <div style="text-align: center; font-size: 12px; margin-bottom: 2px;">
      ${chart.x}
    </div>

    <div>
      <table style="margin: 0 auto;">
        <tbody>
          ${[
            ...chart.points.map((point) => {
              return `
                <tr>
                  <td>
                    <span style="color: ${
                      point.color
                    }; font-size: 1rem">\u25CF</span>
                  </td>
                  <td style="text-align: right; padding-right: 3px;">
                    ${point.series.name} :
                  </td>
                  
                  <td style="text-align: right; font-weight: 600;">
                    <span>
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.prefix || valuePrefix
                      }</span>
                      ${formatThousands(
                        point.y
                      )}<span style="font-weight: 400; color: 'light-gray';">${
                point.point.postfix || valuePostfix
              }</span>
                    </span>
                  </td>
                  ${
                    includePercentage
                      ? `<td style="text-align: right;">
                    <span>
                      (${Highcharts.numberFormat(point.point.percentage, 2)}%)
                    </span>
                  </td>`
                      : ""
                  }
                </tr>
              `;
            }),
            includeTotal
              ? `<tr>
              <td></td>
              <td style="font-weight: 600; text-align: right; padding-right: 3px;">
                Total :
              </td>
              <td style="text-align:right; font-weight: 600;">
                ${formatThousands(_.sumBy(chart.points, (p) => p.y))}
              </td>
            </tr>`
              : "",
          ].join("")}
        </tbody>
      </table>
    </div>
  `;
}
