import React from "react";
import _ from "lodash";
import { PATHS } from "helpers/Routes";

const TOOL_PAGES = [
  {
    name: "Platform Business",
    path: "/biz/overview",
  },
  {
    name: "Reporting Status",
    path: "/healthcheck/reporting-status",
  },
  {
    name: "Batch Updat AutoPilot Config",
    path: "/tools/batch-update-auto-pilot-config",
  },
  {
    name: "Console Ops Log",
    path: "/console/ops-log",
  },
  {
    name: "Invoice & Payment",
    path: "/ops-mgmt/billing/invoices/wait-for-i2w-approval",
  },
  {
    name: "Publishers Management",
    path: "/ops-mgmt/account/publishers",
  },
  {
    name: "Networks Management",
    path: "/ops-mgmt/account/networks",
  },
  {
    name: "Batch Onboard Ad Units",
    path: "/ops-mgmt/account/onboard-ad-units",
  },
  {
    name: "Batch Update Ad Units",
    path: "/ops-mgmt/account/batch-update-ad-units",
  },
  {
    name: "Segment Research Groups",
    path: "/segment-research/list",
  },
  {
    name: "VSE",
    path: "/vse/recipes",
  },
  {
    name: "Vault GAM Accounts",
    path: "/vault-accounts",
  },
  {
    name: "Vault Intowow Users",
    path: "/vault-users",
  },
  {
    name: "App Configurator Management",
    path: "/app-configurator",
  },
  {
    name: "Inventory and Revenue Analysis Dashboard",
    path: PATHS.inventoryRev,
  },
];
const DEFAULT_VALUE = "Go to...";

class NavigateToOtherPages extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      goToPage: DEFAULT_VALUE,
    };

    this.handleChanged = this.handleChanged.bind(this);
  }

  handleChanged(e) {
    const path = e.target.value;
    if (path === DEFAULT_VALUE) {
      return;
    }

    window.open(`${window.location.origin}/#${path}`);
    // TODO
    // this.setState({});
  }

  render() {
    return (
      <>
        <select
          // defaultValue={DEFAULT_VALUE}
          value={DEFAULT_VALUE}
          onChange={this.handleChanged}
          className="border shadow rounded border-blue-600 bg-blue-900 px-2 text-sm text-gray-300 hover:bg-blue-800"
        >
          <option value={DEFAULT_VALUE}>{DEFAULT_VALUE}</option>

          {TOOL_PAGES.map((p) => {
            return (
              <option key={p.name} value={p.path}>
                {p.name}
              </option>
            );
          })}

          {/* {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            );
          })} */}
        </select>
        {/* <button
          type="button"
          className="flex items-center text-sm px-4 text-gray-300 border border-blue-600 shadow bg-blue-900 hover:bg-blue-800 rounded"
          onClick={this.handleGoToBatchUpdateTool}
        >
          <div className="mr-2">
            <FaTools></FaTools>
          </div>
          Batch Update
        </button> */}
      </>
    );
  }
}

export default NavigateToOtherPages;
