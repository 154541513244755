import React from "react";
import _ from "lodash";

class ItemsFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleClearInput = this.handleClearInput.bind(this);
  }

  componentDidMount() {
    if (this.props.inputValue && typeof this.props.inputValue === "string") {
      this.setState({ inputValue: this.props.inputValue });
    }
    this.filterData = _.debounce((event) => {
      this.props.handleSearch(event.target.value);
    }, 300);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isInSearchingMode !== this.props.isInSearchingMode) {
      if (!this.props.isInSearchingMode) {
        this.setState({ inputValue: "" });
      }
    }
  }

  handleOnChange(event) {
    event.persist();
    this.filterData(event);
    this.setState({
      inputValue: event.target.value,
    });
  }

  handleClearInput() {
    this.setState({ inputValue: "" });
  }

  render() {
    const { isInSearchingMode, placeholder } = this.props;
    const { inputValue } = this.state;

    return (
      <input
        type="text"
        className={`rounded flex w-full appearance-none border-2 border-gray-300 bg-white px-4 py-1 leading-normal ${
          isInSearchingMode
            ? "border-blue-600"
            : "focus:outline-none hover:shadow hover:border-blue-300 focus:border-blue-400 focus:shadow-inner"
        }`}
        placeholder={placeholder}
        onChange={this.handleOnChange}
        value={inputValue}
      ></input>
    );
  }
}

export default ItemsFilter;
