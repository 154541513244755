import _ from "lodash";

// The types of parameter.
const VALUETYPE = {
  ARRAY: "array",
  OBJECT: "object",
  EMPTY_ARRAY: "empty_array",
  NULL: "null",
  UNDEFINED: "undefined",
};

// The types of differences.
export const CHANGETYPE = {
  VALUE_CHANGED: "value_changed",
  VALUE_ADDED: "value_added",
  VALUE_REMOVED: "value_removed",
  ARRAY_CHANGED: "array_changed",
  ARRAY_ADDED: "array_added",
  ARRAY_REMOVED: "array_removed",
  TYPE_DIFFERENT: "type_different",
};

// Get the parameter type.
function getType(value) {
  if (_.isArray(value)) {
    return value.length > 0 ? VALUETYPE.ARRAY : VALUETYPE.EMPTY_ARRAY;
  }
  if (value === null) return VALUETYPE.NULL;
  if (value === undefined) return VALUETYPE.UNDEFINED;
  return typeof value;
}

// Check if the value exists.
// undefined, null, or an empty array are considered nonexistent.
function checkExist(type) {
  return (
    type !== VALUETYPE.UNDEFINED &&
    type !== VALUETYPE.NULL &&
    type !== VALUETYPE.EMPTY_ARRAY
  );
}

// Compare two arrays without considering the element order.
function compareArrays(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  return _.differenceWith(arr1, arr2, _.isEqual).length === 0;
}

export function compareTwoObjectsDiff(
  from,
  to,
  uniqIdMap = new Map(),
  sortedArray = []
) {
  const result = [];

  function compareDiff(from, to, path = "", diffs = []) {
    const fromType = getType(from);
    const toType = getType(to);

    const hasFrom = checkExist(fromType);
    const hasTo = checkExist(toType);

    // Case 1: Both parameters do not exist.
    if (!hasFrom && !hasTo) return;

    // Case 2: The parameter "to" does not exist.
    if (hasFrom && !hasTo) {
      diffs.push({
        path,
        type:
          toType === VALUETYPE.EMPTY_ARRAY || fromType === VALUETYPE.ARRAY
            ? CHANGETYPE.ARRAY_REMOVED
            : CHANGETYPE.VALUE_REMOVED,
        from,
        to,
      });
      return;
    }

    // Case 3: The parameter "from" does not exist.
    if (!hasFrom && hasTo) {
      diffs.push({
        path,
        type:
          fromType === VALUETYPE.EMPTY_ARRAY || toType === VALUETYPE.ARRAY
            ? CHANGETYPE.ARRAY_ADDED
            : CHANGETYPE.VALUE_ADDED,
        from,
        to,
      });
      return;
    }

    // Case 4: The types of the "from" and "to" parameters are different.
    if (fromType !== toType) {
      diffs.push({
        path,
        type: CHANGETYPE.TYPE_DIFFERENT,
        from,
        to,
      });
      return;
    }

    if (fromType === VALUETYPE.OBJECT) {
      // Case 5: Compare the two objects "from" and "to".
      const fromKeys = Object.keys(from);
      const toKeys = Object.keys(to);

      const allKeys = _.uniq([...fromKeys, ...toKeys]);

      allKeys.forEach((key) => {
        const newPath = path !== "" ? `${path}.${key}` : key;
        compareDiff(from[key], to[key], newPath, diffs);
      });
    } else if (fromType === VALUETYPE.ARRAY) {
      // Case 6: Compare the two arrays "from" and "to".
      const newPath = _.replace(path, /\[.*?\]/g, "[]");
      // Check if the comparison should be done based on the "unique key".
      const uniqKey = uniqIdMap.get(newPath) ? uniqIdMap.get(newPath) : "";
      if (uniqKey === "") {
        // Check if the comparison should be done based on the "position".
        if (sortedArray.indexOf(newPath) >= 0) {
          const maxLegnth = Math.max(from.length, to.length);
          for (let i = 0; i < maxLegnth; i++) {
            const newPath = path !== "" ? `${path}[${i}]` : `[${i}]`;
            compareDiff(from[i], to[i], newPath, diffs);
          }
        } else if (!compareArrays(from, to)) {
          diffs.push({
            path,
            type: CHANGETYPE.ARRAY_CHANGED,
            from,
            to,
          });
        }
      } else {
        if (from.length > 0 || to.length > 0) {
          // _.keyBy([{ a: 1, b: 3 }, { a: 2, b: 4 }])
          // => { 1: {a: 1, b: 2}, 2: {a: 2, b: 4} }
          const fromKeyObj = _.keyBy(from, uniqKey);
          const toKeyObj = _.keyBy(to, uniqKey);

          const allKeys = _.uniq([
            ...Object.keys(fromKeyObj),
            ...Object.keys(toKeyObj),
          ]);

          allKeys.forEach((key) => {
            const newPath =
              path !== ""
                ? `${path}[${uniqKey}:${key}]`
                : `[${uniqKey}:${key}]`;
            compareDiff(fromKeyObj[key], toKeyObj[key], newPath, diffs);
          });
        }
      }
    } else if (from !== to) {
      // Case 6: Compare the two parameter "from" and "to" directly.
      diffs.push({
        path,
        type: CHANGETYPE.VALUE_CHANGED,
        from,
        to,
      });
    }
  }

  compareDiff(from, to, "", result);

  return result;
}

// Combine the same paths into a single object.
// ex. { path: "a.b.c", from: 1, to: 2 }
// + { path: "a.b.d", from: 2, to: 3 }
// => { path: "a.b", from: { c: 1, d: 2 }, to: { c: 2, d: 3 } }
export function combineDiff(diffs) {
  const regex = /\[.*?\]/;
  return _.toPairs(
    _.groupBy(
      diffs.map((diff) => {
        const diffList = diff.path.split(".");
        return {
          ...diff,
          tempPath:
            diffList.length > 1 && !regex.test(_.last(diffList))
              ? diffList.slice(0, -1).join(".")
              : diff.path,
        };
      }),
      "tempPath"
    )
  ).map(([path, group]) =>
    group.length > 1
      ? {
          path,
          from: _.mapValues(
            _.keyBy(group, (item) => _.last(item.path.split("."))),
            "from"
          ),
          to: _.mapValues(
            _.keyBy(group, (item) => _.last(item.path.split("."))),
            "to"
          ),
          type: CHANGETYPE.VALUE_CHANGED,
        }
      : _.omit(group[0], "tempPath")
  );
}
