import _ from "lodash";

export const OPTIMIZATION_TYPE = {
  DT: "dt",
  CST_V1: "cstv1",
  CST_V2_P: "cstv2p",
  CST_V2_NP: "cstv2np",
};

export function getPricesLimit({ optimization }) {
  let min, max;

  switch (optimization) {
    case OPTIMIZATION_TYPE.DT: {
      min = 0;
      max = 0;
      break;
    }
    case OPTIMIZATION_TYPE.CST_V1:
    case OPTIMIZATION_TYPE.CST_V2_NP:
    case OPTIMIZATION_TYPE.CST_V2_P: {
      min = 1;
      max = 10;
      break;
    }

    default: {
      // nothing
    }
  }

  return { min, max };
}

export function getDetectorPricesLimit({ optimization }) {
  // options: [5,10] (num of prices should be 5 or 10)
  let min, max, options;
  switch (optimization) {
    case OPTIMIZATION_TYPE.DT:
    case OPTIMIZATION_TYPE.CST_V1: {
      min = 1;
      max = 10;
      break;
    }

    case OPTIMIZATION_TYPE.CST_V2_NP:
    case OPTIMIZATION_TYPE.CST_V2_P: {
      // TODO: Check CST Config's traffic_ratio_option_index
      // -> min = max = cstConfig.traffic_ratio_option_index
      // -> 0: 10, 1: 5 (also based on group_traffic_ratio_options)
      // should be 5 or 10...
      options = [5, 10];
    }

    default: {
      // nothing
    }
  }

  return { min, max, options };
}

export function getXPricesLimit({ optimization }) {
  let min, max;

  switch (optimization) {
    case OPTIMIZATION_TYPE.DT: {
      min = 0;
      max = 0;
      break;
    }
    case OPTIMIZATION_TYPE.CST_V1:
    case OPTIMIZATION_TYPE.CST_V2_NP:
    case OPTIMIZATION_TYPE.CST_V2_P: {
      min = 0;
      max = 10;
      break;
    }

    default: {
      // nothing
    }
  }

  return { min, max };
}

export function getCatchallPricesLimit({ optimization }) {
  // options: [5,10] (num of prices should be 5 or 10)
  let min, max, options;
  switch (optimization) {
    case OPTIMIZATION_TYPE.DT:
    case OPTIMIZATION_TYPE.CST_V1: {
      min = 1;
      max = 10;
      break;
    }

    case OPTIMIZATION_TYPE.CST_V2_NP:
    case OPTIMIZATION_TYPE.CST_V2_P: {
    }

    default: {
      // nothing
    }
  }

  return { min, max, options };
}

export function isPricesInvalid({ prices, limit }) {
  if (!prices) return false;
  let isInvalid = false;
  let errMsg = null;

  // const { limit } = this.props;
  // limit.options: [5,10] (num of prices could only be 5 or 10)
  const { min, max, options } = limit;

  if (options) {
    if (_.indexOf(options, prices.length) === -1) {
      isInvalid = true;
      errMsg = `Num of prices should be ${options.join(" or ")}`;
      return { isInvalid, errMsg };
      // this.setState({ isInvalid, errMsg });
      // return;
    }
  }

  if (min) {
    if (prices.length < min) {
      isInvalid = true;
      errMsg = `Should have at least ${min} num of prices`;
      return { isInvalid, errMsg };
      // this.setState({ isInvalid, errMsg });
      // return;
    }
  }

  if (max) {
    if (prices.length > max) {
      isInvalid = true;
      errMsg = `Cannot have more than ${max} num of prices`;
      return { isInvalid, errMsg };
      // this.setState({ isInvalid, errMsg });
      // return;
    }
  }

  // no negative
  // round 2 decimals
  for (let p of prices) {
    if (p < 0) {
      isInvalid = true;
      errMsg = `${p} cannot be negative`;
      return { isInvalid, errMsg };
      // this.setState({ isInvalid, errMsg });
      // break;
    }

    const numOfDecimals = (p.toString().split(".")[1] || []).length;
    if (numOfDecimals > 2) {
      isInvalid = true;
      errMsg = `${p}: only 2 decimal places are allowed`;
      return { isInvalid, errMsg };
      // this.setState({ isInvalid, errMsg });
      // break;
    }
  }

  // this.setState({ isInvalid, errMsg });
  return { isInvalid, errMsg };
}

export function validateUnitSettings(settings) {
  let errors = [];

  if (!settings || _.isEmpty(settings)) {
    errors = ["Cannot be empty"];
    return errors;
  }

  if (!_.isArray(settings)) {
    errors = ["Should be an array"];
    return errors;
  }

  let ids = [];
  let unitIds = [];
  for (const rule of settings) {
    const unitErrors = _validateUnitSettingRule(rule);
    if (unitErrors) {
      errors = [...unitErrors];
      break;
    }

    // ids are unique
    if (_.indexOf(ids, rule.id) !== -1) {
      errors.push(`ID should be unique. ID ${rule.id} appeared more than once`);
    }
    // units cannot be in multiple settings
    const dupeUnits = _.intersection(unitIds, rule.units);
    if (dupeUnits.length > 0) {
      errors.push(
        `Units cannot appear in multiple rules. ${dupeUnits.join(
          ", "
        )} appears more than once`
      );
    }

    ids.push(rule.id);
    unitIds = unitIds.concat(rule.units);
  }

  return errors.length > 0 ? errors : null;
}

function _validateUnitSettingRule(rule) {
  let errors = [];
  const {
    id,
    name,
    units,
    prices,
    detector_prices,
    catchall_prices,
    x_prices,
  } = rule;
  // validate:
  // id - int
  // name - string
  // units - array of gamUnitId
  // prices - array of float
  // detector_prices - array of float
  // catchall_prices - array of float
  // x_prices - array of float (remove if empty)

  if (id === 0 || id < 1) {
    errors.push("id should be larger than 0");
  } else if (!id) {
    errors.push("Missing id");
  } else if (id && !_.isInteger(id)) {
    errors.push(`id should be an integer. ${id} is not an integer`);
  }

  if (!name) {
    errors.push("Missing name");
  } else if (typeof name !== "string") {
    errors.push("name should be a string");
  }

  if (!units || units.length === 0) {
    errors.push("Missing units");
  } else if (!_.isArray(units)) {
    errors.push("units should be an array of GAM Unit Ids");
  } else {
    // should be integers
    const invalidUnitIds = _.filter(units, (u) => !_.isInteger(u));
    if (invalidUnitIds.length > 0) {
      errors.push(`units should be integers. ${invalidUnitIds.join(", ")}`);
    }
  }

  if (!prices || prices.length === 0) {
    errors.push("Missing prices");
  } else if (!_.isArray(prices)) {
    errors.push("prices should be an array of prices");
  } else {
    // should be integers
    const invalids = _.filter(prices, (u) => !_.isNumber(u));
    if (invalids.length > 0) {
      errors.push(`prices should be numbers. ${invalids.join(", ")}`);
    }
  }

  if (!detector_prices || detector_prices.length === 0) {
    errors.push("Missing detector_prices");
  } else if (!_.isArray(detector_prices)) {
    errors.push("detector_prices should be an array of prices");
  } else {
    // should be integers
    const invalids = _.filter(detector_prices, (u) => !_.isNumber(u));
    if (invalids.length > 0) {
      errors.push(`detector_prices should be numbers. ${invalids.join(", ")}`);
    }
  }

  // catchall prices is optional
  if (catchall_prices) {
    if (catchall_prices.length === 0) {
      errors.push("catchall_prices is empty, please remove it");
    } else if (!_.isArray(catchall_prices)) {
      errors.push("catchall_prices should be an array of prices");
    } else {
      // should be integers
      const invalids = _.filter(catchall_prices, (u) => !_.isNumber(u));
      if (invalids.length > 0) {
        errors.push(
          `catchall_prices should be numbers. ${invalids.join(", ")}`
        );
      }
    }
  }

  if (x_prices) {
    if (x_prices.length === 0) {
      errors.push("x_prices is empty, please remove it");
    } else if (!_.isArray(x_prices)) {
      errors.push("x_prices should be an array of prices");
    } else {
      // should be integers
      const invalids = _.filter(x_prices, (u) => !_.isNumber(u));
      if (invalids.length > 0) {
        errors.push(`x_prices should be numbers. ${invalids.join(", ")}`);
      }
    }
  }

  return errors.length > 0 ? errors : null;
}
