import React from "react";
import _ from "lodash";
import Select from "react-select";

import { NetworkAPI, UnitAPI } from "apis";

let cache = {};

const HARDCODE = {
  KEY: "in2w_key23",
};

class CustomTargetingValuesSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      items: [],
      options: [],
      selectedValue: null,

      errMsg: null,
    };

    this.asyncFn = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.customFilter = this.customFilter.bind(this);
  }

  async componentDidMount() {
    const { networkId, keyId } = this.props;
    if (!networkId) {
      return;
    }

    if (!keyId) {
      return;
    }

    this.asyncFn = await this.queryCustomKeyValues();
  }

  async queryCustomKeyValues() {
    const { networkId, keyId, managedValueIds, benchmarkValueIds, keyName } =
      this.props;
    this.setState({ isLoading: true });
    const isHardcode = keyName === HARDCODE.KEY;

    try {
      let items = [];
      let units = [];
      const cacheKey = `${networkId}_${keyId}`;

      if (isHardcode) {
        const cacheKeyUnit = `${networkId}_${keyName}`;
        if (cache[cacheKeyUnit]) {
          units = cache[cacheKeyUnit];
        } else {
          const result = await UnitAPI.getUnitFullpathHashList({
            networkId,
          });
          units = result;
          cache[cacheKeyUnit] = units;
        }
      }

      if (cache[cacheKey]) {
        items = cache[cacheKey];
      } else {
        const r = await NetworkAPI.getCustomTargetingValues({
          networkId,
          ids: [keyId],
        });
        items = r.items;
        cache[cacheKey] = items;
      }
      let options = _.map(items, (r) => {
        // name is displayed different based on matchType
        // PREFIX: {value}*
        // BROAD: ~{value}
        // BROAD_PREFIX: ~{value}*
        // EXACT: {value}
        const mt = r.matchType;
        const front = mt === "BROAD" || mt === "BROAD_PREFIX" ? "~" : "";
        const back = mt === "PREFIX" || mt === "BROAD_PREFIX" ? "*" : "";
        let name = `${front}${r.name}${back}`;
        const unit = _.find(units, { fullpathHash: r.name });

        const showDisplayName = r.displayName && r.displayName !== name;
        return {
          value: r.id,
          id: r.id,
          name,
          displayName: r.displayName,
          label: `${r.id} - ${name}${
            showDisplayName ? ` (${r.displayName})` : ""
          }${
            isHardcode && unit
              ? ` [${unit.unitId} (${unit.extUnitId}) ${unit.name}]`
              : ""
          }`,
        };
      });
      options = _.sortBy(options, "name");

      if (managedValueIds && managedValueIds.length > 0) {
        const selectedValue = _.filter(options, (option) => {
          const mids = _.map(managedValueIds, _.parseInt);
          return _.indexOf(mids, option.id) !== -1;
        });

        this.setState({ selectedValue });
        !this.props.isReadonly && this.props.handleOnChange(selectedValue);
      } else if (benchmarkValueIds && benchmarkValueIds.length > 0) {
        const selectedValue = _.filter(options, (option) => {
          return _.indexOf(benchmarkValueIds, option.id) !== -1;
        });

        this.setState({ selectedValue });
        !this.props.isReadonly && this.props.handleOnChange(selectedValue);
      }

      this.setState({
        items,
        options,
        errMsg: null,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: `Error query custom targeting keys: ${err.toString()}`,
      });
    }

    this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps) {
    if (_.parseInt(prevProps.keyId) !== _.parseInt(this.props.keyId)) {
      await this.queryCustomKeyValues();
      this.setState({
        selectedValue: null,
      });
      this.props.handleOnChange(null);
    }
  }

  componentWillUnmount() {
    if (this.asyncFn) {
      this.asyncFn.cancel();
    }
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });
    this.props.handleOnChange(option);
  }

  handleInputChange(input) {
    if (_.isEmpty(input)) return;
    // Check if there is seperator
    // user can paste a list of unit ids
    if (input.includes(",")) {
      const key = this.props.valueKey || "id";
      let parts = input.split(",");
      parts = _.map(_.compact(parts), _.trim);
      if (key === "id") parts = _.map(parts, _.parseInt);
      else if (key === "name") parts = _.map(parts, _.toLower);
      if (parts.length > 0) {
        // check if this is a list of ids
        // console.log(parts, this.state.options);
        let selectedValue = _.filter(this.state.options, (option) => {
          return _.indexOf(parts, option[key]) !== -1;
        });

        const invalidIds = _.difference(parts, _.map(selectedValue, key));
        if (invalidIds.length > 0) {
          const errMsg = `Failed to find these ${key}s: ${invalidIds.join(
            ", "
          )}`;
          this.setState({ errMsg });
        } else {
          this.setState({ errMsg: null });
        }

        if (selectedValue.length > 0) {
          const newValues = _.uniqBy(
            [...(this.state.selectedValue || []), ...selectedValue],
            "id"
          );
          this.handleChange(newValues);
          return "";
        }
      }
    }

    return input;
  }

  customFilter(option, searchText) {
    if (typeof searchText !== "string") {
      return false;
    }
    // label includes: id, name, displayName
    let searchKey = this.props.valueKey || "label";
    if (
      option.data[searchKey]
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { managedValueIds } = this.props;
    const { options, selectedValue, errMsg, isLoading } = this.state;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
          isClearable
          isLoading={isLoading}
          onInputChange={this.handleInputChange}
          isDisabled={this.props.isReadonly}
          filterOption={this.customFilter}
        />
        {errMsg && (
          <>
            {/* <div>{managedValueIds && managedValueIds.join(", ")}</div> */}
            <div className="bg-red-100 px-2 py-1 text-red-800">{errMsg}</div>
          </>
        )}
      </>
    );
  }
}

export default CustomTargetingValuesSelector;
