import React from "react";
import _ from "lodash";
import { getSchemaByKey as getSchemaByKeyCSTConfig } from "components/ops-mgmt/account/cst-config/CSTConfigSchema";
import ConfigValueReadonly from "components/control-center/auto-pilot/config/ConfigValueReadonly";
import { FiArrowRight } from "react-icons/fi";

class CSTConfigDiffView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      diffs: [],
    };
  }
  render() {
    const { originalConfig, newConfig, defaultConfig } = this.props;

    const diffs = _calculateCSTConfigDiff(
      originalConfig,
      newConfig,
      defaultConfig
    );

    return (
      <>
        <div className="border-b-2 border-gray-400 text-sm font-semibold uppercase text-gray-600">
          Config Changes ({diffs.length})
        </div>
        <div className="text-sm">
          {diffs.map((diff, i) => {
            const { key, from, to, valueType, section } = diff;
            const title = _.startCase(key);
            return (
              <div key={i} className="border-b -mx-4 mb-2 px-4 pb-2">
                {section && (
                  <div className="font-semibold text-blue-800">{section}:</div>
                )}
                <div className="font-semibold text-blue-800">{title}</div>

                <div className="flex text-gray-600">
                  <div className="flex w-1/2">
                    {/* <div className="text-gray-600">from</div> */}

                    <div>
                      {from.isDefault ? (
                        <DefaultValueView
                          valueType={valueType}
                          value={from.value}
                        ></DefaultValueView>
                      ) : (
                        <CustomValueView
                          valueType={valueType}
                          value={from.value}
                        ></CustomValueView>
                      )}
                    </div>
                  </div>
                  <div className="self-center px-2 text-gray-600">
                    {/* to */}
                    <FiArrowRight></FiArrowRight>
                  </div>

                  <div className="flex w-1/2">
                    {/* <div className="text-gray-600">to</div> */}

                    <div className="font-semibold">
                      {to.isDefault ? (
                        <DefaultValueView
                          valueType={valueType}
                          value={to.value}
                        ></DefaultValueView>
                      ) : (
                        <CustomValueView
                          valueType={valueType}
                          value={to.value}
                        ></CustomValueView>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

class CustomValueView extends React.PureComponent {
  render() {
    const { value, valueType } = this.props;

    return (
      <ConfigValueReadonly
        valueType={valueType}
        configValue={value}
      ></ConfigValueReadonly>
    );
  }
}

class DefaultValueView extends React.PureComponent {
  render() {
    const { value, valueType } = this.props;

    return (
      <div>
        Use Default:{" "}
        <ConfigValueReadonly
          valueType={valueType}
          configValue={value}
        ></ConfigValueReadonly>
      </div>
    );
  }
}

function _calculateCSTConfigDiff(fromConfig, toConfig, defaultConfig, section) {
  let diffs = [];

  const oldKeys = _.keys(fromConfig);
  const newKeys = _.keys(toConfig);
  const keys = _.uniq([...oldKeys, ...newKeys]);

  _.forEach(keys, (key) => {
    const hasFrom = _.has(fromConfig, key);
    const hasTo = _.has(toConfig, key);
    const fromValue = _.get(fromConfig, key);
    const toValue = _.get(toConfig, key);
    const defaultValue = _.get(defaultConfig, key);

    if (
      key !== "ab_test_traffic_allocation" &&
      key !== "connecttv" &&
      key !== "desktop" &&
      key !== "phone" &&
      key !== "tablet"
    ) {
      if (_isObject(fromValue) || _isObject(toValue)) {
        Array.prototype.push.apply(
          diffs,
          _calculateCSTConfigDiff(
            fromValue,
            toValue,
            defaultValue,
            `${section || ""}${section ? " / " : ""}${_.startCase(key)}`
          )
        );
        return true;
      }
    }

    const diff = getDiffObject(
      hasFrom,
      hasTo,
      defaultValue,
      fromValue,
      toValue,
      key,
      section
    );
    if (diff) diffs.push(diff);
  });

  // add schema
  diffs = _.map(diffs, (d) => {
    d.valueType = _.get(getSchemaByKeyCSTConfig(d.key), "valueType");
    return d;
  });
  return diffs;
}

function _isObject(value) {
  return _.isPlainObject(value);
  // return typeof value === "object" && value.length === "undefined";
}

function parseObjectDiff(from, to) {
  const temp = {};
  const oldKeys = Object.keys(from);
  const newKeys = Object.keys(to);
  const keys = _.uniq([...oldKeys, ...newKeys]);

  keys.forEach((key) => {
    if (to[key] === undefined) {
      temp[key] = "NONE";
      return true;
    }

    if (_.isArray(from[key]) && _.isArray(to[key])) {
      if (!compareArrays(from[key], to[key])) {
        temp[key] = to[key];
      }
      return true;
    }

    if (_.isPlainObject(from[key]) && _.isPlainObject(to[key])) {
      const diff = parseObjectDiff(from[key], to[key]);
      if (Object.keys(diff).length > 0) {
        temp[key] = diff;
      }
      return true;
    }

    if (from[key] !== to[key]) {
      temp[key] = to[key];
    }
  });

  return temp;
}

function areObjectsEqual(from, to) {
  if (typeof from !== typeof to) {
    return false;
  }

  if (_.isArray(from) && _.isArray(to)) {
    return compareArrays(from, to);
  }

  if (_.isPlainObject(from) && _.isPlainObject(to)) {
    return compareObjects(from, to);
  }

  return _.isEqual(from, to);
}

// compare two arrays
function compareArrays(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  return _.differenceWith(arr1, arr2, _.isEqual).length === 0;
}

// compare two objects
function compareObjects(obj1, obj2) {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    return areObjectsEqual(value1, value2);
  });
}

function getDiffObject(
  hasFrom,
  hasTo,
  defaultValue,
  fromValue,
  toValue,
  key,
  section
) {
  if (hasFrom && hasTo && !areObjectsEqual(fromValue, toValue)) {
    return {
      key,
      from: {
        value:
          _isObject(toValue) && _isObject(fromValue)
            ? parseObjectDiff(toValue, fromValue)
            : fromValue,
      },
      to: {
        value:
          _isObject(toValue) && _isObject(fromValue)
            ? parseObjectDiff(fromValue, toValue)
            : toValue,
      },
      section,
    };
  }

  if (hasFrom && !hasTo) {
    return {
      key,
      from: {
        value: fromValue,
      },
      to: {
        isDefault: true,
        value: defaultValue,
      },
      section,
    };
  } else if (!hasFrom && hasTo) {
    return {
      key,
      from: {
        isDefault: true,
        value: defaultValue,
      },
      to: {
        value: toValue,
      },
      section,
    };
  }

  return null;
}

export default CSTConfigDiffView;
