import ModalWrapper from "components/common/ModalWrapper";
import React from "react";

class PromptModal extends React.PureComponent {
  render() {
    const { isOpenModal, handleConfirm, handleCancel, header, children } =
      this.props;

    return (
      <ModalWrapper isOpen={isOpenModal} showCloseFooter={false} width="30%">
        <div>
          <div className="mb-2 text-xl font-bold text-gray-700">
            {header || "Caution"}
          </div>

          <div className="mb-4 text-gray-600">{children}</div>

          <div className="flex items-center justify-end gap-2">
            <button
              type="button"
              className="rounded shadow bg-gray-400 px-8 py-2 font-semibold text-white hover:bg-gray-500"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="shadow rounded bg-blue-400 px-8 py-2 font-semibold text-white hover:bg-blue-600"
              onClick={handleConfirm}
            >
              Proceed
            </button>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default PromptModal;
