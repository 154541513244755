import React from "react";
import _ from "lodash";
import { FiArrowRight, FiMinus, FiPlus } from "react-icons/fi";
import {
  CHANGETYPE,
  compareTwoObjectsDiff,
  combineDiff,
} from "helpers/Compare";
import ConfigValueReadonly from "components/control-center/auto-pilot/config/ConfigValueReadonly";

const DISPLAYTYPE = {
  ADD: "add",
  REMOVE: "remove",
  CHANGE: "change",
};

function getDisplayType(changeType) {
  if ([CHANGETYPE.ARRAY_ADDED, CHANGETYPE.VALUE_ADDED].indexOf(changeType) >= 0)
    return DISPLAYTYPE.ADD;
  if (
    [CHANGETYPE.ARRAY_REMOVED, CHANGETYPE.VALUE_REMOVED].indexOf(changeType) >=
    0
  )
    return DISPLAYTYPE.REMOVE;
  return DISPLAYTYPE.CHANGE;
}

class CustomUPRDiffView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      diffs: [],
    };
  }

  render() {
    const { originalRules, newRules } = this.props;
    const regex = /\[.*?\]/;

    // Indicate the path and name with a unique key.
    const uniqIdMap = new Map()
      .set("", "id")
      .set("[].targetingSection.customs[]", "keyId");

    // Indicate an array of paths that need to match by location.
    const sortedArray = ["[].targetingSection.customs"];

    const diffs = compareTwoObjectsDiff(
      originalRules,
      newRules,
      uniqIdMap,
      sortedArray
    );

    const parseDiff = combineDiff(diffs);

    return (
      <>
        <div className="border-b-2 border-gray-400 text-sm font-semibold uppercase text-gray-600">
          Config Changes ({diffs.length})
        </div>
        <div className="text-sm">
          {parseDiff.map((diff, i) => {
            const { path, from, to, type } = diff;

            const pathList = path.split(".");
            const lastEle = pathList.pop();
            const title = pathList
              .map((str) => (regex.test(str) ? str : _.startCase(str)))
              .join(" / ");

            return (
              <div key={i} className="border-b -mx-4 mb-2 px-4 pb-2">
                <div
                  className={`
                    ${
                      getDisplayType(type) === DISPLAYTYPE.ADD &&
                      "border-green-500"
                    }
                    ${
                      getDisplayType(type) === DISPLAYTYPE.REMOVE &&
                      "border-red-500"
                    }
                    ${
                      getDisplayType(type) === DISPLAYTYPE.CHANGE &&
                      "border-orange-500"
                    }
                  border-l-4 pl-2 font-semibold text-blue-800`}
                >
                  <div>{title}</div>
                  <div>
                    {regex.test(lastEle) ? lastEle : _.startCase(lastEle)}
                  </div>
                </div>

                <div className="flex text-gray-600">
                  <div
                    className="flex break-all"
                    style={{ width: "calc(50% - 0.5rem)" }}
                  >
                    <div>
                      <ConfigValueReadonly
                        valueType={type}
                        configValue={from}
                      ></ConfigValueReadonly>
                    </div>
                  </div>
                  <div className="self-center px-2 text-lg">
                    {getDisplayType(type) === DISPLAYTYPE.ADD && (
                      <FiPlus className="text-green-500"></FiPlus>
                    )}
                    {getDisplayType(type) === DISPLAYTYPE.REMOVE && (
                      <FiMinus className="text-red-500"></FiMinus>
                    )}
                    {getDisplayType(type) === DISPLAYTYPE.CHANGE && (
                      <FiArrowRight className="text-orange-500"></FiArrowRight>
                    )}
                  </div>

                  <div
                    className="flex break-all"
                    style={{ width: "calc(50% - 0.5rem)" }}
                  >
                    <div className="font-semibold">
                      <ConfigValueReadonly
                        valueType={type}
                        configValue={to}
                      ></ConfigValueReadonly>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default CustomUPRDiffView;
