import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import solidGauge from "highcharts/modules/solid-gauge";
import highchartsMore from "highcharts/highcharts-more";

highchartsMore(Highcharts);
solidGauge(Highcharts);

Highcharts.setOptions({
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
});

class HighchartChart extends React.PureComponent {
  render() {
    const { options } = this.props;
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}

export default HighchartChart;
