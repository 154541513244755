import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { IoMdRefresh } from "react-icons/io";

class ForceRefreshButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { handleRefresh, isLoading, id = "force-refresh-btn" } = this.props;

    return (
      <>
        <button
          type="button"
          className="hover:shadow p-1 text-gray-600 hover:text-blue-600"
          onClick={() => {
            if (isLoading) return;
            handleRefresh();
          }}
          disabled={isLoading}
          data-tip
          data-for={id}
        >
          {isLoading ? "loading..." : <IoMdRefresh></IoMdRefresh>}
        </button>

        <ReactTooltip id={id} type="dark" effect="solid">
          Force Refresh
        </ReactTooltip>
      </>
    );
  }
}

export default ForceRefreshButton;
