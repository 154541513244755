import React from "react";
import _ from "lodash";
import BrandPriceSettingsTable from "./BrandedPriceSettingsTable";
import BrandPriceSettingControl from "./BrandedPriceSettingControl";

class BrandedPriceSettingsSection extends React.PureComponent {
  constructor(props) {
    super(props);

    const { brandedPriceSettings } = this.props;

    this.state = {
      isEditing: false,
      currentBrandPriceSetting: null,
      brandedPriceSettings,
      currentIndexInSettings: null, // for edit and remove
    };

    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleAddSetting = this.handleAddSetting.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleOpenDuplicate = this.handleOpenDuplicate.bind(this);
  }

  handleOpenEdit({ setting }, index) {
    let currentBrandPriceSetting = {};
    if (setting) currentBrandPriceSetting = setting;
    this.setState({
      isEditing: true,
      currentBrandPriceSetting,
      currentIndexInSettings: index,
    });
    this.props.handleIsEditing(true);
    // remove from brandedPriceSettings
    // this.handleRemove(setting, index);
  }

  handleRemove(item, index) {
    const { brandedPriceSettings } = this.state;
    let newSettings = _.cloneDeep(brandedPriceSettings) || [];
    newSettings.splice(index, 1);

    this.setState({
      brandedPriceSettings: newSettings,
    });
    this.props.handleBrandPriceSettingsChanged(newSettings);
  }

  handleOpenDuplicate(item) {
    this.setState({ isEditing: true, currentBrandPriceSetting: item });
    this.props.handleIsEditing(true);
  }

  handleCancel() {
    this.setState({
      isEditing: false,
      currentBrandPriceSetting: null,
      currentIndexInSettings: null,
    });
    this.props.handleIsEditing(false);
  }

  handleAddSetting(newSetting) {
    const { currentIndexInSettings } = this.state;
    let newSettings = _.cloneDeep(this.props.brandedPriceSettings) || [];
    if (
      currentIndexInSettings !== null &&
      currentIndexInSettings !== undefined
    ) {
      newSettings[currentIndexInSettings] = newSetting;
    } else {
      newSettings.push(newSetting);
    }

    this.setState({
      brandedPriceSettings: newSettings,
      isEditing: false,
      currentIndexInSettings: null,
    });
    this.props.handleBrandPriceSettingsChanged(newSettings);
    this.props.handleIsEditing(false);
  }

  render() {
    const { currency, networkId, brandedPriceSettings } = this.props;
    const { isEditing, currentBrandPriceSetting } = this.state;

    return (
      <div>
        {!isEditing && (
          <button
            type="button"
            className="rounded shadow mb-2 bg-blue-100 px-2 py-1 text-sm text-blue-600 hover:bg-blue-200"
            onClick={this.handleOpenEdit}
          >
            Create new brand price setting
          </button>
        )}

        {isEditing && (
          <BrandPriceSettingControl
            networkId={networkId}
            currency={currency}
            currentBrandPriceSetting={currentBrandPriceSetting}
            handleAddSetting={this.handleAddSetting}
            handleCancel={this.handleCancel}
          ></BrandPriceSettingControl>
        )}

        {brandedPriceSettings && (
          <BrandPriceSettingsTable
            items={brandedPriceSettings}
            currency={currency}
            handleEdit={this.handleOpenEdit}
            handleRemove={this.handleRemove}
            handleOpenDuplicate={this.handleOpenDuplicate}
          ></BrandPriceSettingsTable>
        )}
      </div>
    );
  }
}

export default BrandedPriceSettingsSection;
