import React from "react";
import _ from "lodash";
import Select from "react-select";
import { notify } from "react-notify-toast";

import { AppConfiguratorAPI } from "apis";

import TextAreaWithJsonCheck from "components/common/TextAreaWithJsonCheck";
import PromptModal from "components/common/PromptModal";
import LoadingUI from "components/common/LoadingUI";

const EDITTYPE = {
  SCHEMA: "schema",
  DEFAULTS: "defaults",
};

const SWITCHOPTION = {
  APP: "app",
  CONTENT: "content",
};

class AppConfiguratorViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isOpenModal: false,
      isDisableSave: true,
      errMsg: null,

      apps: null,
      options: [],
      selectedApp: null,
      schema: null,
      defaults: null,
      editType: EDITTYPE.DEFAULTS,
      selectedConfig: null,
    };

    this.tempSelected = {
      option: null,
      value: null,
    };

    this.handleAppSelect = this.handleAppSelect.bind(this);
    this.handleEditTypeChanged = this.handleEditTypeChanged.bind(this);
    this.handleConfigChanged = this.handleConfigChanged.bind(this);

    this.handelModalConfirm = this.handelModalConfirm.bind(this);
    this.handelModalCancel = this.handelModalCancel.bind(this);

    this.handleSave = this.handleSave.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true, errMsg: null });

    try {
      const apps = await AppConfiguratorAPI.getApps();

      if (apps) {
        const options = apps.map((app) => ({
          value: app.name,
          label: _.startCase(app.name),
        }));
        this.setState({ apps, options });
      }
    } catch (err) {
      this.setState({ errMsg: JSON.parse(err).error || err });
      notify.show("Failed to get apps.", "error");
    }

    this.setState({ isLoading: false });
  }

  async handleAppSelect(app, isForceExecution = false) {
    const { value } = app;
    const { selectedApp, isDisableSave } = this.state;

    if (selectedApp && selectedApp.value === value) return;

    if (!isDisableSave && !isForceExecution) {
      this.tempSelected.option = SWITCHOPTION.APP;
      this.tempSelected.value = app;
      this.setState({ isOpenModal: true });
      return;
    }

    this.setState({ selectedApp: app, isLoading: true, errMsg: null });

    try {
      const result = await AppConfiguratorAPI.getAppSchemaAndDefaultValues({
        appName: value,
      });
      if (result) {
        const { schema, defaults } = result;
        this.setState({
          schema,
          defaults,
          isDisableSave: true,
        });
      }
    } catch (err) {
      this.setState({ errMsg: JSON.parse(err).error || err });
      notify.show("Failed to get schema and default values.", "error");
    }

    this.handleEditTypeChanged(this.state.editType, true);
    this.setState({ isLoading: false });
  }

  handleEditTypeChanged(type, isForceExecution = false) {
    const { selectedApp, editType, isDisableSave } = this.state;

    if (!isForceExecution && editType === type) return;

    if (!isDisableSave && !isForceExecution) {
      this.tempSelected.option = SWITCHOPTION.CONTENT;
      this.tempSelected.value = type;
      this.setState({ isOpenModal: true });
      return;
    }

    this.setState({ editType: type });
    if (!selectedApp) return;

    const config =
      type === EDITTYPE.DEFAULTS ? this.state.defaults : this.state.schema;
    this.setState({
      isDisableSave: true,
      errMsg: null,
      selectedConfig: JSON.stringify(config, null, 4),
    });
  }

  handleConfigChanged(input) {
    try {
      const { editType, schema, defaults } = this.state;
      this.setState({ selectedConfig: input });
      const config = JSON.parse(input);
      const originalConfig = editType === EDITTYPE.DEFAULTS ? defaults : schema;
      this.setState({ isDisableSave: _.isEqual(originalConfig, config) });
    } catch (e) {
      this.setState({ isDisableSave: true });
    }
  }

  handelModalConfirm() {
    const { option, value } = this.tempSelected;

    if (option === SWITCHOPTION.APP) {
      this.handleAppSelect(value, true);
    } else {
      this.handleEditTypeChanged(value, true);
    }

    this.tempSelected = {
      option: null,
      value: null,
    };
    this.setState({ isOpenModal: false });
  }

  handelModalCancel() {
    this.setState({ isOpenModal: false });
  }

  async handleSave() {
    const { selectedApp, editType, selectedConfig } = this.state;
    const config = JSON.parse(selectedConfig);
    this.setState({ isLoading: true, errMsg: null });

    try {
      const result =
        editType === EDITTYPE.DEFAULTS
          ? await AppConfiguratorAPI.createOrUpdateAppDefaultValues({
              appName: selectedApp.value,
              defaultValues: config,
            })
          : await AppConfiguratorAPI.createOrUpdateAppSchema({
              appName: selectedApp.value,
              configSchema: config,
            });
      if (result && result.op === "ok") {
        notify.show("Saved successfully", "success");
        if (editType === EDITTYPE.DEFAULTS) this.setState({ defaults: config });
        else this.setState({ schema: config });
        this.setState({ isDisableSave: true });
      } else notify.show("Failed to save. Error: no result response", "error");
    } catch (err) {
      this.setState({ errMsg: JSON.parse(err).error || err });
      notify.show(`Failed to save.`, "error");
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      isDisableSave,
      isOpenModal,
      isLoading,
      options,
      editType,
      selectedConfig,
      selectedApp,
      errMsg,
    } = this.state;

    return (
      <>
        <div className="bg-blue-900 px-2">
          <div className="text-sm font-bold text-blue-100">&nbsp;</div>
        </div>
        <div className="bg-white px-12">
          <div className="flex justify-between pb-4 pt-8 ">
            <div className="text-4xl font-extrabold text-gray-900">
              App Configurator Management
            </div>
          </div>
        </div>
        <div
          className="relative bg-gray-200 px-12 py-8"
          style={{ height: "calc(100% - 7.6875rem)" }}
        >
          {errMsg && (
            <div
              className="absolute mx-auto w-1/3 break-words text-left text-red-700"
              style={{ left: "30%", top: "2rem" }}
            >
              {errMsg}
            </div>
          )}
          <label className="font-semibold text-gray-800">Select App</label>
          <Select
            className="mb-4 w-1/4"
            value={selectedApp}
            onChange={(value) => this.handleAppSelect(value)}
            options={options}
          ></Select>
          <div className="mb-2 flex flex-wrap gap-2">
            {[EDITTYPE.DEFAULTS, EDITTYPE.SCHEMA].map((item) => (
              <div
                key={item}
                className={`cursor-pointer rounded-md px-3 py-1 ${
                  editType === item
                    ? "bg-blue-400 text-gray-100"
                    : "bg-blue-100 text-black hover:bg-blue-200"
                }`}
                onClick={() => this.handleEditTypeChanged(item)}
              >
                {_.startCase(item)}
              </div>
            ))}
          </div>
          {selectedApp && (
            <div className="relative pb-2">
              <TextAreaWithJsonCheck
                rows={15}
                inputValue={selectedConfig}
                handleInputChanged={this.handleConfigChanged}
              ></TextAreaWithJsonCheck>
              <button
                type="button"
                className={`shadow rounded absolute  px-8 py-2 font-semibold text-white  ${
                  isDisableSave
                    ? "cursor-not-allowed bg-blue-200"
                    : "bg-blue-400 hover:bg-blue-600"
                }`}
                style={{ top: "24rem", right: "0rem" }}
                disabled={isDisableSave}
                onClick={this.handleSave}
              >
                Save
              </button>
            </div>
          )}
        </div>

        <PromptModal
          header="Unsaved Changes Alert"
          isOpenModal={isOpenModal}
          handleConfirm={this.handelModalConfirm}
          handleCancel={this.handelModalCancel}
        >
          You have unsaved changes. If you switch now, these changes will be
          lost. <br />
          Do you want to proceed?
        </PromptModal>

        {isLoading && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-gray-800 opacity-25">
            <LoadingUI iconOnly={true}></LoadingUI>
          </div>
        )}
      </>
    );
  }
}

export default AppConfiguratorViewer;
