import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import LoadingUI from "../common/LoadingUI";
import ModalWrapper from "components/common/ModalWrapper";
import { AnatomyAPI, CstAPI } from "apis";
import CstAnatomyPriceSection from "./AnatomyCstPriceSection";
import AnatomyCstFilter from "./AnatomyCstFilter";
import {
  transformReports,
  calculateDashboardReports,
  getSegmentReports,
  getDetectorFloorPriceMap,
} from "./AnatomyCstReportHelper";
import AnatomyDemandTypeFilter from "./AnatomyDemandTypeFilter";
import { PRESETS } from "constants/DemandType";
import AnatomyCstPerformanceSection from "./AnatomyCstPerformanceSection";
import AnatomyTimeAggregationFilter from "./AnatomyTimeAggregationFilter";
import AnatomyCstSegmentsSection from "./AnatomyCstSegmentsSection";
import AnatomyTimezoneTypeSelector from "./AnatomyTimezoneTypeSelector";
import SegmentStickyHeader from "./SegmentStickyHeader";
import UnitDetailHeader from "components/common/UnitDetailHeader";
import GoToAnatomyButton from "components/common/GoToAnatomyButton";
import GoToInsightButton from "components/common/GoToInsightButton";
import GoToAPConfigButton from "components/common/GoToAPConfigButton";
import InfoWidget from "components/common/InfoWidget";
import DemandTypeCompareChart from "./DemandTypeCompareChart";
import DetectorMetricChart from "./DetectorMetricChart";
import OptVsDetectorCompareChart from "./OptVsDetectorCompareChart";
import Checkbox from "components/common/Checkbox";
import GodviewConfidenceChart from "./GodviewConfidenceChart";
import AnatomyNoPbSelector from "./AnatomyNoPbSelector";

const DEFAULT = {
  dateRangeNum: 3,
  demandTypePreset: PRESETS.NETWORK_BILLABLE,
  aggregation: 24,
  dataType: "ALL", // "NO_PB"
};

class AnatomyCSTViewer extends React.Component {
  constructor(props) {
    super(props);

    const urlQueryParams = queryString.parse(this.props.location.search);

    const noPbType = _.get(urlQueryParams, "dataType", DEFAULT.dataType);

    this.state = {
      isLoading: false,
      data: null,
      errMsg: null,

      unitInfo: null,

      noPbType,

      // date range filter
      dateRangeNum: DEFAULT.dateRangeNum,
      startDate: null,
      endDate: null,
      endDateNotIncludeToday: null,

      // filter
      demandTypePreset: DEFAULT.demandTypePreset,
      demandTypes: null,
      aggregation: DEFAULT.aggregation,
      timezoneType: "NETWORK", // NETWORK, TAIPEI
      isIncludeGhostImp: false,

      filteredData: null,

      // segment
      segmentData: null,
      selectedUprSegmentId: null,
      selectedGroupTypes: null,
      detectorFloorPriceMap: null,

      includeToday: true, // show current day's data
      filterLatestDate: null, // the latest date to filter out

      // dateRange
      st: null,
      et: null,

      // compare chart
      isModalOpen: false,
      detailChartProps: null,

      // detector chart
      isDetectorModalOpen: false,
      detectorChartProps: null,

      // o vs d chart
      isOptVsDetectorModalOpen: false,

      // godview chart
      isGodviewModalOpen: false,
    };

    this.getUnitInfo = this.getUnitInfo.bind(this);
    this.getFilterParams = this.getFilterParams.bind(this);
    this.getStartDateEndDate = this.getStartDateEndDate.bind(this);
    this.handleFilterClicked = this.handleFilterClicked.bind(this);
    this.handleGetSegmentReport = this.handleGetSegmentReport.bind(this);
    this.handleDemandTypesChanged = this.handleDemandTypesChanged.bind(this);

    this.recalculateReports = this.recalculateReports.bind(this);
    this.handleTimezoneTypeChanged = this.handleTimezoneTypeChanged.bind(this);
    this.handleNoPbTypeChanged = this.handleNoPbTypeChanged.bind(this);
    this.handleIsIncludeGhostImpChanged =
      this.handleIsIncludeGhostImpChanged.bind(this);

    this.handleSelectedGroupTypes = this.handleSelectedGroupTypes.bind(this);

    this.handleIncludeTodayChanged = this.handleIncludeTodayChanged.bind(this);

    this.handleSetExtremes = this.handleSetExtremes.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.setDemandTypeCompareProps = this.setDemandTypeCompareProps.bind(this);
    this.setOVDProps = this.setOVDProps.bind(this);
    this.setGodviewProps = this.setGodviewProps.bind(this);
    this.setDetectorProps = this.setDetectorProps.bind(this);

    this.itemRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.unitId !== this.props.unitId) {
      this.setState({ unitId: this.props.unitId });
    }

    if (prevState.unitInfo === null && this.state.unitInfo) {
      // console.log("unitInfo filled");
      // wait for unitInfo to fill in state
      setTimeout(() => {
        this.handleFilterClicked();
      });
    }
  }

  componentDidMount() {
    if (this.props.unitId) {
      document.title = `A:${this.props.unitId} | CST Anatomy`;
    }
  }

  _changeQueryParams(queryParams) {
    this.props.history.push({
      search: "?" + new URLSearchParams(queryParams).toString(),
    });
  }

  getUnitInfo(unitInfo) {
    // console.log(unitInfo);
    this.setState({ unitInfo, demandTypes: unitInfo.billableDemandTypes });
  }

  getFilterParams() {
    const {
      demandTypePreset,
      demandTypes,
      aggregation,
      includeToday,
      filterLatestDate,
      timezoneType,
      timezone,
      isIncludeGhostImp,
      selectedUprSegmentId,
      unitInfo,
      noPbType,
    } = this.state;

    return {
      demandTypePreset,
      demandTypes,
      aggregation,
      includeToday,
      filterLatestDate,
      timezoneType,
      timezone,
      isIncludeGhostImp,
      segmentId: selectedUprSegmentId,
      costCpm: unitInfo.costCPMInCurrency,
      noPbType,
    };
  }

  setDetectorProps(props) {
    this.setState({
      detectorChartProps: props,
      isDetectorModalOpen: true,
      currentScrollY: window.scrollY,
    });
  }

  setDemandTypeCompareProps() {
    const detailChartProps = {
      groupType: "o",
      layerNum: "all",
      metric: "rrpm",
    };
    this.setState({
      detailChartProps,
      isModalOpen: true,
      currentScrollY: null,
    });
  }

  setOVDProps() {
    const detailChartProps = {
      groupType: "o",
      layerNum: "all",
      metric: "rrpm",
    };
    this.setState({
      detailChartProps,
      isOptVsDetectorModalOpen: true,
      currentScrollY: null,
    });
  }

  setGodviewProps() {
    const detailChartProps = {
      groupType: "o",
      layerNum: "all",
      metric: "rrpm",
    };
    this.setState({
      detailChartProps,
      isGodviewModalOpen: true,
      currentScrollY: null,
    });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false,
      detailChartProps: null,
      isDetectorModalOpen: false,
      detectorChartProps: null,
      isOptVsDetectorModalOpen: false,
      isGodviewModalOpen: false,
    });

    setTimeout(() => {
      if (this.state.currentScrollY) {
        window.scrollTo({
          top: this.state.currentScrollY,
        });
      }
    });
  }

  getStartDateEndDate({
    startDate,
    endDate,
    endDateNotIncludeToday,
    dateRangeNum,
  }) {
    this.setState({ startDate, endDate, endDateNotIncludeToday, dateRangeNum });
  }

  handleDemandTypesChanged(preset, demandTypes) {
    this.setState({ demandTypePreset: preset, demandTypes });
    this.recalculateReports({ demandTypes });
  }

  handleIncludeTodayChanged(newIncludeToday) {
    this.setState({ includeToday: newIncludeToday });
    this.recalculateReports({ includeToday: newIncludeToday });
  }

  handleIsIncludeGhostImpChanged(isIncludeGhostImp) {
    this.setState({ isIncludeGhostImp });
    this.recalculateReports({ isIncludeGhostImp });
  }

  handleNoPbTypeChanged({ type }) {
    this.setState({ noPbType: type });
    this.recalculateReports({ noPbType: type });
    this._changeQueryParams({ dataType: type });
  }

  async handleFilterClicked() {
    await this.getAnatomyData();
  }

  async getAnatomyData() {
    this.setState({
      isLoading: true,
      data: null,
      filteredData: null,
      selectedUprSegmentId: null,
      filterLatestDate: null,
    });

    try {
      const {
        unitInfo,
        noPbType,
        demandTypes,
        aggregation,
        dateRangeNum,
        startDate,
        endDate,
        endDateNotIncludeToday,
        includeToday,
        isIncludeGhostImp,
      } = this.state;
      const { networkId, extUnitId, timezone } = unitInfo;

      let et = moment.tz(endDate, timezone).unix();
      let st = moment.tz(startDate, timezone).unix();

      const params = {
        networkId,
        extGamUnitId: extUnitId,
        startDate: st,
        endDate: et,
      };
      const { dashboard } = await AnatomyAPI.getCstAnatomyReportLink(params);
      const data = transformReports(dashboard.data);

      // endDateNotIncludeToday
      const filterLatestDate = moment
        .tz(endDateNotIncludeToday, timezone)
        .unix();
      const filteredData = calculateDashboardReports({
        reports: data,
        isNoPb: noPbType === "NO_PB",
        demandTypes,
        aggregation,
        costCpm: unitInfo.costCPMInCurrency,
        filterLatestDate: includeToday ? null : filterLatestDate,
        isIncludeGhostImp,
      });
      // console.log(unitInfo, filteredData);
      // console.log(filteredData.segment_performance_report);

      this.setState({
        data,
        filteredData,
        selectedGroupTypes: filteredData.groupTypes,
        filterLatestDate,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errorMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isLoading: false });
  }

  handleGetSegmentReport(segmentId, shouldScroll = true) {
    this.setState({ selectedUprSegmentId: segmentId });
    this.updateSegmentData({
      filteredData: this.state.filteredData,
      segmentId,
      isNoPb: this.state.noPbType === "NO_PB",
    });

    if (shouldScroll) {
      setTimeout(() => {
        const element = document.getElementById("anatomy-chart-head");
        if (element) {
          window.scrollTo({
            behavior: "smooth",
            top: element.offsetTop,
          });
        }
      }, 500);
    }
  }

  updateSegmentData({ filteredData, segmentId }) {
    const segmentData = getSegmentReports(filteredData, segmentId);

    const detectorFloorPriceMap = getDetectorFloorPriceMap({
      reports: segmentData,
    });
    this.setState({ segmentData, detectorFloorPriceMap });
  }

  recalculateReports({
    aggregation,
    demandTypes,
    includeToday,
    isIncludeGhostImp,
    noPbType,
  }) {
    console.log("recalculate reports");
    // console.log(
    //   "recal",
    //   aggregation,
    //   demandTypes,
    //   includeToday,
    //   isIncludeGhostImp,
    //   this.state
    // );
    // time aggregation changed
    // demand types changed
    // console.log(aggregation, demandTypes);
    const { data, unitInfo, filterLatestDate } = this.state;
    if (aggregation) {
      const isNoPb = this.state.noPbType === "NO_PB";
      const filteredData = calculateDashboardReports({
        reports: data,
        demandTypes: this.state.demandTypes,
        aggregation,
        isNoPb,
        costCpm: unitInfo.costCPMInCurrency,
        filterLatestDate: this.state.includeToday ? null : filterLatestDate,
        isIncludeGhostImp: this.state.isIncludeGhostImp,
      });
      // console.log("filteredData", filteredData);
      this.setState({ filteredData, aggregation });
      this.updateSegmentData({
        filteredData,
        segmentId: this.state.selectedUprSegmentId,
        // isNoPb, TODO: remove
      });
    } else if (noPbType) {
      const isNoPb = noPbType === "NO_PB";
      const filteredData = calculateDashboardReports({
        reports: data,
        demandTypes: this.state.demandTypes,
        aggregation: this.state.aggregation,
        isNoPb,
        costCpm: unitInfo.costCPMInCurrency,
        filterLatestDate: this.state.includeToday ? null : filterLatestDate,
        isIncludeGhostImp: this.state.isIncludeGhostImp,
      });

      this.setState({ filteredData, noPbType });
      this.updateSegmentData({
        filteredData,
        segmentId: this.state.selectedUprSegmentId,
        isNoPb,
      });
    } else if (demandTypes) {
      // console.log("filter by demandTypes", demandTypes);
      const isNoPb = this.state.noPbType === "NO_PB";
      const filteredData = calculateDashboardReports({
        reports: data,
        demandTypes,
        aggregation: this.state.aggregation,
        isNoPb,
        costCpm: unitInfo.costCPMInCurrency,
        filterLatestDate: this.state.includeToday ? null : filterLatestDate,
        isIncludeGhostImp: this.state.isIncludeGhostImp,
      });
      this.setState({ filteredData, demandTypes });
      this.updateSegmentData({
        filteredData,
        segmentId: this.state.selectedUprSegmentId,
        isNoPb,
      });
    } else if (
      isIncludeGhostImp !== undefined &&
      isIncludeGhostImp !== this.state.isIncludeGhostImp
    ) {
      const isNoPb = this.state.noPbType === "NO_PB";
      const filteredData = calculateDashboardReports({
        reports: data,
        demandTypes: this.state.demandTypes,
        aggregation: this.state.aggregation,
        isNoPb,
        costCpm: unitInfo.costCPMInCurrency,
        filterLatestDate: this.state.includeToday ? null : filterLatestDate,
        isIncludeGhostImp: isIncludeGhostImp,
      });
      // console.log("filteredData", filteredData);
      this.setState({ filteredData, isIncludeGhostImp });
      this.updateSegmentData({
        filteredData,
        segmentId: this.state.selectedUprSegmentId,
        isNoPb,
      });
    } else if (
      includeToday !== undefined &&
      includeToday !== this.state.includeToday
    ) {
      const isNoPb = this.state.noPbType === "NO_PB";
      const filteredData = calculateDashboardReports({
        reports: data,
        demandTypes: this.state.demandTypes,
        aggregation: this.state.aggregation,
        isNoPb,
        costCpm: unitInfo.costCPMInCurrency,
        filterLatestDate: includeToday ? null : filterLatestDate,
        isIncludeGhostImp: this.state.isIncludeGhostImp,
      });
      // console.log("filteredData", filteredData);
      this.setState({ filteredData, includeToday });
      this.updateSegmentData({
        filteredData,
        segmentId: this.state.selectedUprSegmentId,
        isNoPb,
      });
    }
  }

  handleSelectedGroupTypes(groupTypes) {
    this.setState({ selectedGroupTypes: groupTypes });
  }

  handleTimezoneTypeChanged({ timezoneType }) {
    this.setState({ timezoneType });
  }

  handleSetExtremes({ min, max }) {
    console.log("set extremes", min, max);
    this.setState({ st: min, et: max });
  }

  render() {
    const { unitId } = this.props;
    const {
      isLoading,
      errorMsg,
      data,
      unitInfo,

      noPbType,

      dateRangeNum,
      startDate,
      endDate,

      demandTypePreset,
      selectedUprSegmentId,
      segmentData,
      detectorFloorPriceMap,

      demandTypes,
      aggregation,
      timezoneType,
      isIncludeGhostImp,

      filteredData,
      selectedGroupTypes,
      includeToday,

      st,
      et,

      isModalOpen,
      detailChartProps,

      // detector chart
      isDetectorModalOpen,
      detectorChartProps,

      // o vs d chart
      isOptVsDetectorModalOpen,

      isGodviewModalOpen,
    } = this.state;

    const infoList = unitInfo
      ? [
          {
            mainId: unitInfo.gamUnitId,
            mainTitle: unitInfo.name,
            subId: unitInfo.networkId,
            subTitle: unitInfo.networkName,
            timezone: unitInfo.timezone,
          },
        ]
      : [];

    return (
      <>
        {/* Diagnoser like header */}
        {/* <div className="border-b bg-gray-100 py-2 px-4">
          <div className="flex items-center justify-between">
            <div className="text-xl font-bold text-gray-800">CST Anatomy</div>

            <div className="flex justify-end gap-2">
              <GoToAnatomyButton unitId={unitId}></GoToAnatomyButton>
              <GoToInsightButton unitId={unitId}></GoToInsightButton>
              <GoToAPConfigButton unitId={unitId}></GoToAPConfigButton>
            </div>
          </div>
        </div> */}

        <div className="bg-blue-900 px-4 py-1">
          <div className="flex items-center justify-between">
            <div className="text-sm font-bold text-blue-100">CST Anatomy</div>
            <div className="flex justify-end gap-2">
              <GoToAnatomyButton unitId={unitId}></GoToAnatomyButton>
              <GoToInsightButton unitId={unitId}></GoToInsightButton>
              <GoToAPConfigButton unitId={unitId}></GoToAPConfigButton>
            </div>
          </div>
        </div>

        <UnitDetailHeader
          unitId={unitId}
          getUnitInfo={this.getUnitInfo}
        ></UnitDetailHeader>

        {infoList.length !== 0 && (
          <InfoWidget
            itemRefs={[this.itemRef]}
            itemList={infoList}
          ></InfoWidget>
        )}
        <div className="min-h-screen bg-gray-300 p-4" ref={this.itemRef}>
          {unitInfo && (
            <>
              <div className="-pb-4 mb-2 bg-white px-4 pt-3">
                <AnatomyCstFilter
                  timezone={unitInfo.timezone}
                  timezoneType={timezoneType}
                  selectedDateRange={dateRangeNum}
                  startDate={startDate}
                  endDate={endDate}
                  refillStartEndDate={this.getStartDateEndDate}
                  handleFilterClicked={this.handleFilterClicked}
                ></AnatomyCstFilter>
              </div>

              {isLoading && <LoadingUI></LoadingUI>}

              {errorMsg && <div className="text-red-600">{errorMsg}</div>}

              {filteredData && (
                <>
                  <div className="bg-white p-2">
                    <div className="flex gap-4">
                      <AnatomyTimeAggregationFilter
                        aggregation={aggregation}
                        recalculate={this.recalculateReports}
                      ></AnatomyTimeAggregationFilter>

                      <AnatomyTimezoneTypeSelector
                        timezoneType={timezoneType}
                        handleTimezoneTypeChanged={
                          this.handleTimezoneTypeChanged
                        }
                      ></AnatomyTimezoneTypeSelector>

                      <AnatomyNoPbSelector
                        type={noPbType}
                        handleNoPbTypeChanged={this.handleNoPbTypeChanged}
                      ></AnatomyNoPbSelector>
                    </div>

                    <AnatomyDemandTypeFilter
                      demandTypePreset={demandTypePreset}
                      demandTypes={unitInfo.billableDemandTypes}
                      billableDemandTypes={unitInfo.billableDemandTypes}
                      handleDemandTypesChanged={this.handleDemandTypesChanged}
                      // recalculate={this.recalculateReports}
                    ></AnatomyDemandTypeFilter>

                    <IncludeGhostImprFilter
                      isIncludeGhostImp={isIncludeGhostImp}
                      handleChanged={this.handleIsIncludeGhostImpChanged}
                    ></IncludeGhostImprFilter>
                  </div>

                  <AnatomyCstSegmentsSection
                    currency={unitInfo.currency}
                    timezone={unitInfo.timezone}
                    timezoneType={timezoneType}
                    demandTypes={demandTypes}
                    aggregation={aggregation}
                    isIncludeGhostImp={isIncludeGhostImp}
                    noPbType={noPbType}
                    // includeToday={includeToday}
                    dateRangeNum={dateRangeNum}
                    items={filteredData.upr_segments}
                    data={filteredData.segment_performance_report}
                    handleGetSegmentReport={this.handleGetSegmentReport}
                  ></AnatomyCstSegmentsSection>

                  {selectedUprSegmentId && (
                    <>
                      <SegmentStickyHeader
                        currency={unitInfo.currency}
                        uprSegmentId={selectedUprSegmentId}
                        items={filteredData.upr_segments}
                        groupTypes={filteredData.groupTypes} // all groupTypes
                        selectedGroupTypes={selectedGroupTypes}
                        detectorFloorPriceMap={detectorFloorPriceMap}
                        handleSelectedGroupTypes={this.handleSelectedGroupTypes}
                        includeToday={includeToday}
                        handleIncludeTodayChanged={
                          this.handleIncludeTodayChanged
                        }
                      ></SegmentStickyHeader>

                      <CstAnatomyPriceSection
                        timezone={unitInfo.timezone}
                        timezoneType={timezoneType}
                        data={segmentData}
                        uprSegmentId={selectedUprSegmentId}
                        demandTypes={demandTypes}
                        dateRangeNum={dateRangeNum}
                        aggregation={aggregation}
                        isIncludeGhostImp={isIncludeGhostImp}
                        noPbType={noPbType}
                        selectedGroupTypes={selectedGroupTypes}
                        includeToday={includeToday}
                        st={st}
                        et={et}
                        handleSetExtremes={this.handleSetExtremes}
                      ></CstAnatomyPriceSection>

                      <AnatomyCstPerformanceSection
                        timezone={unitInfo.timezone}
                        timezoneType={timezoneType}
                        data={segmentData}
                        uprSegmentId={selectedUprSegmentId}
                        demandTypes={demandTypes}
                        dateRangeNum={dateRangeNum}
                        aggregation={aggregation}
                        selectedGroupTypes={selectedGroupTypes}
                        includeToday={includeToday}
                        isIncludeGhostImp={isIncludeGhostImp}
                        noPbType={noPbType}
                        st={st}
                        et={et}
                        handleSetExtremes={this.handleSetExtremes}
                        setDemandTypeCompareProps={
                          this.setDemandTypeCompareProps
                        }
                        setOVDProps={this.setOVDProps}
                        setDetectorProps={this.setDetectorProps}
                        setGodviewProps={this.setGodviewProps}
                      ></AnatomyCstPerformanceSection>

                      {isModalOpen && (
                        <DemandTypeCompareModal
                          isOpenViewModal={isModalOpen}
                          handleClose={this.handleCloseModal}
                          detailChartProps={detailChartProps}
                          unitInfo={unitInfo}
                          data={segmentData}
                          groupTypes={filteredData.groupTypes} // all groupTypes
                          getFilterParams={this.getFilterParams}
                        ></DemandTypeCompareModal>
                      )}

                      {isOptVsDetectorModalOpen && (
                        <OptVsDetectorModal
                          isOpenViewModal={isOptVsDetectorModalOpen}
                          handleClose={this.handleCloseModal}
                          detailChartProps={detailChartProps}
                          unitInfo={unitInfo}
                          segmentId={selectedUprSegmentId}
                          getFilterParams={this.getFilterParams}
                          data={data}
                        ></OptVsDetectorModal>
                      )}
                      {/* 
                      {isGodviewModalOpen && (
                        <GodviewModal
                          isOpenViewModal={isGodviewModalOpen}
                          handleClose={this.handleCloseModal}
                          detailChartProps={detailChartProps}
                          unitInfo={unitInfo}
                          segmentId={selectedUprSegmentId}
                          getFilterParams={this.getFilterParams}
                          data={data}
                          // segmentData={segmentData}
                          // data={segmentData.performance_report}
                        ></GodviewModal>
                      )} */}

                      {/* This modal is open when clicked inside charts */}
                      {isDetectorModalOpen && (
                        <DetectorMetricModal
                          isOpenViewModal={isDetectorModalOpen}
                          handleClose={this.handleCloseModal}
                          detectorChartProps={detectorChartProps}
                          unitInfo={unitInfo}
                          getFilterParams={this.getFilterParams}
                        ></DetectorMetricModal>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

function IncludeGhostImprFilter({ isIncludeGhostImp, handleChanged }) {
  return (
    <>
      <div className="-mt-4 flex items-center">
        <label className="flex cursor-pointer items-center px-4 py-2">
          <Checkbox
            isChecked={isIncludeGhostImp}
            onChange={() => handleChanged(!isIncludeGhostImp)}
          ></Checkbox>
          <span className="ml-1 align-middle text-sm font-semibold text-gray-600 hover:text-gray-700">
            Include $0 ghost impression in price priority
          </span>
        </label>
      </div>
    </>
  );
}

class DetectorMetricModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const { detectorChartProps } = props;
    const { point, data } = detectorChartProps;

    this.state = {
      datetime: point.datetime,
      groupType: point.groupType,
      layerNum: point.layerNum,
      metric: point.metric,
      data,
    };
  }

  render() {
    const { isOpenViewModal, handleClose, unitInfo, getFilterParams } =
      this.props;
    const { groupType, layerNum, metric, data, datetime } = this.state;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <div className="mb-2 text-lg font-semibold">
          {"Detector Prices by Metric"}
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: "600px" }}>
          <DetectorMetricChart
            unitInfo={unitInfo}
            data={data}
            getFilterParams={getFilterParams}
            groupType={groupType}
            layerNum={layerNum}
            metric={metric}
            datetime={datetime}
          ></DetectorMetricChart>
        </div>
      </ModalWrapper>
    );
  }
}

class DemandTypeCompareModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const { detailChartProps } = props;

    this.state = {
      groupType: detailChartProps.groupType,
      layerNum: detailChartProps.layerNum,
      metric: detailChartProps.metric,
    };
  }

  render() {
    const {
      isOpenViewModal,
      handleClose,
      unitInfo,
      data,
      groupTypes,
      getFilterParams,
    } = this.props;
    const { groupType, layerNum, metric } = this.state;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <div className="mb-2 text-lg font-semibold">
          {"Performance by Demand Type Groups"}
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: "600px" }}>
          <DemandTypeCompareChart
            unitInfo={unitInfo}
            data={data}
            groupTypes={groupTypes} // all groupTypes
            getFilterParams={getFilterParams}
            groupType={groupType}
            layerNum={layerNum}
            metric={metric}
          ></DemandTypeCompareChart>
        </div>
      </ModalWrapper>
    );
  }
}

class OptVsDetectorModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const { detailChartProps } = props;

    this.state = {
      groupType: detailChartProps.groupType,
      layerNum: detailChartProps.layerNum,
      metric: detailChartProps.metric,

      confidenceReports: null,
      errMsg: null,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { unitInfo, segmentId } = this.props;
    // console.log(unitInfo, segmentId);
    const { networkId, gamUnitId } = unitInfo;
    this.setState({ isLoading: true });
    try {
      const params = {
        networkId,
        unitId: gamUnitId,
        uprSegmentId: segmentId,
      };
      const r = await CstAPI.getGodviewConfidenceReport(params);
      // console.log(r);
      this.setState({ confidenceReports: r, errMsg: null, isLoading: false });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: err.toString(), isLoading: false });
    }
  }

  render() {
    const {
      isOpenViewModal,
      handleClose,
      unitInfo,
      data,
      groupTypes,
      getFilterParams,
      segmentId,
      detailChartProps,
    } = this.props;
    const {
      groupType,
      layerNum,
      metric,
      errMsg,
      confidenceReports,
      isLoading,
    } = this.state;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <div className="mb-2 text-lg font-semibold">
          O vs O's Shadow vs Godview
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: "600px" }}>
          <div>
            {errMsg && <div className="text-red-600">{errMsg}</div>}
            {isLoading && <LoadingUI></LoadingUI>}
          </div>

          {confidenceReports && (
            <OptVsDetectorCompareChart
              unitInfo={unitInfo}
              data={data}
              confidenceReports={confidenceReports}
              segmentId={segmentId}
              getFilterParams={getFilterParams}
              detailChartProps={detailChartProps}
              //
              groupType={groupType}
              layerNum={layerNum}
              metric={metric}
            ></OptVsDetectorCompareChart>
          )}
        </div>
      </ModalWrapper>
    );
  }
}

class GodviewModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const { detailChartProps } = props;

    this.state = {
      groupType: detailChartProps.groupType,
      layerNum: detailChartProps.layerNum,
      metric: detailChartProps.metric,

      confidenceReports: null,
      errMsg: null,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { unitInfo, segmentId } = this.props;
    // console.log(unitInfo, segmentId);
    const { networkId, gamUnitId } = unitInfo;
    this.setState({ isLoading: true });
    try {
      const params = {
        networkId,
        unitId: gamUnitId,
        uprSegmentId: segmentId,
      };
      const r = await CstAPI.getGodviewConfidenceReport(params);
      // console.log(r);
      this.setState({ confidenceReports: r, errMsg: null, isLoading: false });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: err.toString(), isLoading: false });
    }
  }

  render() {
    const {
      isOpenViewModal,
      handleClose,
      unitInfo,
      data,
      groupTypes,
      getFilterParams,
      segmentId,
      detailChartProps,
    } = this.props;
    const {
      groupType,
      layerNum,
      metric,
      errMsg,
      confidenceReports,
      isLoading,
    } = this.state;

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <div className="mb-2 text-lg font-semibold">
          Godview with Confidence Interval
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: "600px" }}>
          {errMsg && <div className="text-red-600">{errMsg}</div>}
          {isLoading && <LoadingUI></LoadingUI>}
          {confidenceReports && (
            <GodviewConfidenceChart
              unitInfo={unitInfo}
              data={data}
              confidenceReports={confidenceReports}
              segmentId={segmentId}
              getFilterParams={getFilterParams}
              detailChartProps={detailChartProps}
              //
              groupType={groupType}
              layerNum={layerNum}
              metric={metric}
            ></GodviewConfidenceChart>
          )}
        </div>
      </ModalWrapper>
    );
  }
}

export default withRouter(AnatomyCSTViewer);
