import React from "react";

import ModalWrapper from "../../../common/ModalWrapper";
import { NetworkAPI } from "apis";
import LoadingUI from "components/common/LoadingUI";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const buttonClass = "px-2 py-0 text-sm rounded border";
const buttonActionClass =
  "bg-gray-200 hover:bg-gray-300 text-gray-900 border-gray-400 shadow";
const buttonDisabledClass =
  "bg-gray-100 text-gray-400 border-gray-300 cursor-not-allowed";

class CompareBuildModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,
      build: props.build,

      comparison: null,
      compareBuildType: "LATEST",
      comparisonBuildId: null,

      isShowReleaseNote: false,
      diffPageIdx: 1,
      overflowRows: [],
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.compareBuild = this.compareBuild.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);

    this.rowRefs = [];
  }

  async componentDidMount() {
    //
    await this.compareBuild();
    // console.log(this.props.builds);
  }

  componentDidUpdate(prevProps, prevState) {
    const { comparison, overflowRows } = this.state;

    if (!prevState.comparison && comparison && comparison.buildInputDiff) {
      const list = overflowRows.map((d) => d);
      comparison.buildInputDiff.forEach((_, i) => {
        if (this.rowRefs[i]) {
          const rowHeight = this.rowRefs[i].clientHeight
            ? this.rowRefs[i].clientHeight
            : 0;
          if (rowHeight >= 80) {
            list[i] = true;
          }
        }
      });
      this.setState({
        overflowRows: list,
      });
    }
  }

  async compareBuild() {
    this.setState({ isLoading: true, errMsg: null });
    try {
      const { networkId, buildId, build } = this.props;
      const r = await NetworkAPI.createBuildComparison({
        networkId,
        packageName: build.packageName,
        baseBuildId: buildId,
        // comparisonBuildId,
      });
      this.setState({
        comparison: r,
      });
    } catch (err) {
      this.setState({
        errMsg: err,
      });
    }
    this.setState({ isLoading: false });
  }

  async createBuildComparison({
    networkId,
    packageName,
    baseBuildId,
    comparisonBuildId,
  }) {
    return await NetworkAPI.createBuildComparison({
      networkId,
      packageName,
      baseBuildId,
      comparisonBuildId,
    });
  }

  handleChangeType(type) {
    this.setState({
      compareBuildType: type,
    });
  }

  handleCancel() {
    this.props.handleClose();
  }

  handleShowReleaseNote = () => {
    this.setState({ isShowReleaseNote: !this.state.isShowReleaseNote });
  };

  render() {
    const {
      isLoading,
      errMsg,
      comparison,
      compareBuildType,
      comparisonBuildId,
      isShowReleaseNote,
      diffPageIdx,
      overflowRows,
    } = this.state;
    const { isOpenViewModal, handleClose, buildId, build } = this.props;

    const headerCls = "text-gray-600 text-ml font-bold";
    const titleCls = "text-gray-600 text-sm font-normal";
    const textCls = "text-gray-800 text-sm font-bold";

    const diffVersionsList =
      comparison &&
      comparison.packageVersionDiff &&
      comparison.packageVersionDiff.diffVersions
        ? comparison.packageVersionDiff.diffVersions
        : [];
    diffVersionsList.sort((a, b) => b.version - a.version);
    const diffVersionsCounts = diffVersionsList.length;
    const diffVersionsPage = Math.ceil(diffVersionsCounts / 10);

    return (
      <ModalWrapper
        isOpen={isOpenViewModal}
        showCloseFooter={true}
        handleClose={handleClose}
      >
        <>
          <div className="border-b -mx-6 mb-4 px-6 pb-2 text-lg font-bold">
            {buildId}{" "}
            <span className="font-normal">
              ({build.packageName} version {build.packageVersion})
            </span>
          </div>

          <div style={{ height: "420px" }}>
            <div>
              <div>Compare with:</div>
              <div>
                <label htmlFor="radio_latest" className="cursor-pointer">
                  <input
                    id="radio_latest"
                    type="radio"
                    value="LATEST"
                    checked={compareBuildType === "LATEST"}
                    onChange={() => this.handleChangeType("LATEST")}
                  />{" "}
                  Latest
                </label>
              </div>{" "}
            </div>

            {isLoading && <LoadingUI></LoadingUI>}
            {comparison && (
              <div
                className="border shadow rounded mt-2 overflow-y-auto border-gray-400 px-3 pb-3 pt-2"
                style={{ height: "calc(100% - 70px)" }}
              >
                <div className={headerCls}>Base & Comparison Builds</div>
                <div className="flex">
                  <div className={`w-32 ${titleCls}`}>
                    <div>Base Build</div>
                    <div>Compare Build</div>
                    <div>Status</div>
                  </div>
                  <div className={textCls}>
                    <div>
                      <span className={`pr-1 leading-tight ${titleCls}`}>
                        :
                      </span>
                      {comparison.baseBuild.buildId
                        ? comparison.baseBuild.buildId
                        : "-"}
                      <span className={`ml-1 ${titleCls}`}>
                        (Package Version{" "}
                        <span className={textCls}>
                          {comparison.baseBuild.packageVersion
                            ? comparison.baseBuild.packageVersion
                            : "-"}
                        </span>
                        )
                      </span>
                    </div>
                    <div>
                      <span className={`pr-1 leading-tight ${titleCls}`}>
                        :
                      </span>
                      {comparison.comparisonBuild.buildId
                        ? comparison.comparisonBuild.buildId
                        : "-"}
                      <span className={`ml-1 ${titleCls}`}>
                        (Package Version{" "}
                        <span className={textCls}>
                          {comparison.comparisonBuild.packageVersion
                            ? comparison.comparisonBuild.packageVersion
                            : "-"}
                        </span>
                        )
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className={`pr-1 leading-tight ${titleCls}`}>
                        :
                      </span>
                      {comparison.hasDiff &&
                      comparison.packageVersionDiff.status
                        ? `${diffVersionsCounts} version ${comparison.packageVersionDiff.status}`
                        : "-"}
                      {comparison.hasDiff && diffVersionsCounts > 0 ? (
                        <div
                          className="ml-2 cursor-pointer"
                          onClick={this.handleShowReleaseNote}
                        >
                          {isShowReleaseNote ? (
                            <IoIosArrowUp></IoIosArrowUp>
                          ) : (
                            <IoIosArrowDown></IoIosArrowDown>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  {isShowReleaseNote && (
                    <div
                      className="rounded bg-gray-200 px-2 py-1"
                      style={{
                        height: `calc(${
                          diffVersionsList.length * 21
                        }px + 0.5rem)`,
                        maxHeight: "calc(210px + 0.5rem)",
                      }}
                    >
                      {diffVersionsList
                        .slice((diffPageIdx - 1) * 10, diffPageIdx * 10)
                        .map((diff) => {
                          return (
                            <div className="flex gap-2" key={diff.version}>
                              <div className={titleCls}>Version</div>
                              <div className={textCls}>
                                {diff.version}{" "}
                                <span className={titleCls}>:</span>
                              </div>
                              <div
                                className={`${textCls} truncate`}
                                style={{ maxWidth: "60rem" }}
                                title={diff.releaseNotes}
                              >
                                {diff.releaseNotes}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                  {diffVersionsPage > 1 && isShowReleaseNote && (
                    <div className="mt-2 flex justify-center gap-2">
                      <button
                        className={`${
                          diffPageIdx === 1
                            ? buttonDisabledClass
                            : buttonActionClass
                        } ${buttonClass}`}
                        onClick={() =>
                          this.setState({ diffPageIdx: diffPageIdx - 1 })
                        }
                        disabled={diffPageIdx === 1}
                      >
                        Prev
                      </button>
                      <div className="text-center" style={{ minWidth: "2rem" }}>
                        {diffPageIdx}
                        <span className="mx-1">/</span>
                        {diffVersionsPage}
                      </div>
                      <button
                        className={`${
                          diffPageIdx === diffVersionsPage
                            ? buttonDisabledClass
                            : buttonActionClass
                        } ${buttonClass}`}
                        onClick={() =>
                          this.setState({ diffPageIdx: diffPageIdx + 1 })
                        }
                        disabled={diffPageIdx === diffVersionsPage}
                      >
                        Next
                      </button>
                    </div>
                  )}
                </div>

                <div className={headerCls}>
                  Build Input Difference{" "}
                  {comparison.buildInputDiff &&
                    `(${comparison.buildInputDiff.length})`}
                </div>
                {comparison.buildInputDiff && (
                  <table className="border shadow table w-full text-sm">
                    <thead className="border-b bg-gray-200 text-xs text-blue-800">
                      <tr>
                        {["Path", "Type", "Base", "Comparison", ""].map(
                          (title, i) => {
                            return (
                              <th
                                className="border px-2 py-1 align-top"
                                key={`${i}${title}`}
                              >
                                {title}
                              </th>
                            );
                          }
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {comparison.buildInputDiff.map((diff, i) => (
                        <tr
                          className={`border-b hover:bg-gray-100 ${
                            overflowRows[i] !== undefined
                              ? "cursor-pointer"
                              : ""
                          }`}
                          key={i}
                          ref={(el) => (this.rowRefs[i] = el)}
                          onClick={() => {
                            if (overflowRows[i] === undefined) return;
                            const list = overflowRows.map((d) => d);
                            list[i] = !list[i];
                            this.setState({
                              overflowRows: list,
                            });
                          }}
                        >
                          <td
                            className="border px-3 py-1 align-top"
                            style={{ maxWidth: "9rem" }}
                          >
                            <ExtendContent isExtend={overflowRows[i]}>
                              {diff.path || "-"}
                            </ExtendContent>
                          </td>
                          <td className="border px-3 py-1 align-top">
                            {diff.type}
                          </td>
                          <td
                            className="border px-3 py-1 align-top"
                            style={{ maxWidth: "12rem" }}
                          >
                            <ExtendContent isExtend={overflowRows[i]}>
                              {diff.base || "-"}
                            </ExtendContent>
                          </td>
                          <td
                            className="border px-3 py-1 align-top"
                            style={{ maxWidth: "12rem" }}
                          >
                            <ExtendContent isExtend={overflowRows[i]}>
                              {diff.comparison || "-"}
                            </ExtendContent>
                          </td>
                          <td className="border break-words px-0 py-1 align-top">
                            {overflowRows[i] !== undefined && (
                              <div className="flex justify-center pt-1">
                                {overflowRows[i] ? (
                                  <IoIosArrowDown></IoIosArrowDown>
                                ) : (
                                  <IoIosArrowUp></IoIosArrowUp>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
            <div className="text-red-600">{errMsg}</div>
          </div>
        </>
      </ModalWrapper>
    );
  }
}

class ExtendContent extends React.PureComponent {
  render() {
    return (
      <div
        className="text-ellipsis overflow-hidden break-words"
        style={
          this.props.isExtend
            ? {
                maxHeight: "4.5rem",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }
            : {}
        }
      >
        {this.props.children}
      </div>
    );
  }
}

export default CompareBuildModal;
