import _ from "lodash";

export function getSchemaByKey(configKey) {
  const schemaMap = _schemaMap();
  return _.get(schemaMap, configKey);
}

function _schemaMap() {
  const schemas = _getSchemas();
  return _.mapKeys(schemas, "key");
}

function _getSchemas() {
  return [
    {
      key: "enabled",
      description: "",
      valueType: "boolean",
      section: "amp_rtc",
    },
    {
      key: "managed_ratio",
      description: "",
      valueType: "integer",
      section: "amp_rtc",
    },
    {
      key: "subdomain_name",
      description: "",
      valueType: "string",
      section: "amp_rtc",
    },
    {
      key: "domain_specific_managed_ratios",
      description: "",
      valueType: "domain_specific_managed_ratios",
      section: "amp_rtc",
    },
    {
      key: "benchmarkValues",
      description: "",
      valueType: "benchmarkValues",
      section: "general",
    },
    {
      key: "customDimensionEnabled",
      description: "",
      valueType: "boolean",
      section: "general",
    },
    {
      key: "key",
      description: "",
      valueType: "general_key",
      section: "general",
    },
    {
      key: "managedValues",
      description: "",
      valueType: "managedValues",
      section: "general",
    },
    {
      key: "ab_test_traffic_allocation",
      description: "",
      valueType: "ab_test_traffic_allocation",
    },
    {
      key: "enable_optimization",
      description: "",
      valueType: "boolean",
    },
    {
      key: "group_traffic_ratio_options",
      description: "",
      valueType: "array_array_integer",
    },
    {
      key: "opt_exp_traffic_ratio",
      description: "",
      valueType: "float",
    },
    {
      key: "traffic_ratio_option_index",
      description: "",
      valueType: "integer",
    },
    {
      key: "upr_protection_mode",
      description: "",
      valueType: "string_enum",
      enum: {
        NONE: "NONE",
        DETECTOR: "DETECTOR",
        DETECTOR_AND_X: "DETECTOR_AND_X",
        ALL: "ALL",
      },
    },
    {
      key: "desktop",
      description: "",
      valueType: "inventory_object",
    },
    {
      key: "connecttv",
      description: "",
      valueType: "inventory_object",
    },
    {
      key: "phone",
      description: "",
      valueType: "inventory_object",
    },
    {
      key: "tablet",
      description: "",
      valueType: "inventory_object",
    },
  ];
}

export const RI_CONFIG_SCHEMA = {
  gpt: [
    {
      key: "desktop",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "anchor",
            "out_of_page",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },

    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "anchor",
            "out_of_page",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "anchor",
            "out_of_page",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },
    {
      key: "connecttv", // same as desktop
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "anchor",
            "out_of_page",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },
  ],
  mobile_android: [
    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "app_open",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "app_open",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
      ],
    },
  ],
  mobile_ios: [
    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "app_open",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: [
            "banner",
            "app_open",
            "interstitial",
            "rewarded",
            "others",
          ],
        },
      ],
    },
  ],

  video: [
    {
      key: "desktop",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["instream_audio", "instream_video", "others"],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },

    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["instream_audio", "instream_video", "others"],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["instream_audio", "instream_video", "others"],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },
    {
      key: "connecttv", // same as desktop
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["instream_audio", "instream_video", "others"],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },
  ],

  amp: [
    {
      key: "desktop",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["banner"],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },

    {
      key: "phone",
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["banner"],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },

    {
      key: "tablet", // same as phone
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["banner"],
        },
        {
          key: "operating_systems",
          title: "Operating Systems",
          valueType: "array",
          arrayOptions: ["android", "ios"],
        },
      ],
    },
    {
      key: "connecttv", // same as desktop
      schema: [
        {
          key: "enable_optimization",
          title: "Enable Optimization",
          valueType: "boolean",
        },
        {
          key: "time_granularity",
          title: "Time Granularity",
          valueType: "integer_enum",
          enum: [1, 3, 6],
        },
        {
          key: "optimized_formats",
          title: "Time Granularity",
          valueType: "array",
          arrayOptions: ["banner"],
        },
        {
          key: "browsers",
          title: "Browsers",
          valueType: "array",
          arrayOptions: ["chrome", "safari", "edge", "others"],
        },
      ],
    },
  ],
};
