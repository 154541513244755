import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import MaxCurrentView from "components/snapshot/MaxCurrentView";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import ForceRefreshButton from "components/common/ForceRefreshButton";

class UprUsageEstimation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      pricingRules: null,
    };

    this.handleForceFreshGamInfo = this.handleForceFreshGamInfo.bind(this);
  }

  async componentDidMount() {
    await this.queryNetworkGamInfo({ isForceFresh: false });
  }

  async handleForceFreshGamInfo() {
    await this.queryNetworkGamInfo({ isForceFresh: true });
  }

  async queryNetworkGamInfo({ isForceFresh = false }) {
    this.setState({
      isLoading: true,
    });
    try {
      const { networkId } = this.props;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }

      const { updatedAt, gamInfoResponse } = await NetworkAPI.getNetworkGamInfo(
        { networkId, isForceFresh }
      );
      if (gamInfoResponse.status !== "OK") {
        throw new Error("Failed to get GAM info");
      }

      // {max: xxx, current: xxx}
      const pricingRules = _.get(gamInfoResponse, [
        "results",
        "limits",
        "inventoryAndKeyValues",
        "pricingRules",
      ]);

      this.setState({
        gamInfoUpdateTime: updatedAt,
        pricingRules,

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  render() {
    const { isLoading, errMsg, pricingRules, gamInfoUpdateTime } = this.state;

    if (isLoading) return <LoadingUI></LoadingUI>;

    return (
      <div>
        <div>
          <div className="text-lg font-semibold text-gray-800">
            Current usage:
          </div>
          {gamInfoUpdateTime && (
            <div className="flex items-center gap-1">
              <div className="text-xs text-gray-600">
                GAM Info last updated:{" "}
                <DateTimeFormatter
                  datetime={gamInfoUpdateTime}
                ></DateTimeFormatter>
              </div>
              <ForceRefreshButton
                handleRefresh={this.handleForceFreshGamInfo}
              ></ForceRefreshButton>
            </div>
          )}

          {errMsg ? (
            <div className="text-red-800">{errMsg}</div>
          ) : (
            <div className="text-gray-800">
              {pricingRules && (
                <div>
                  <MaxCurrentView
                    title="Pricing Rules"
                    max={pricingRules.max}
                    current={pricingRules.current}
                    maxHint="200 is an approximate"
                  ></MaxCurrentView>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UprUsageEstimation;
