import React from "react";
import ReactModal from "react-modal";

class ModalWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal() {
    this.props.handleClose();
  }

  render() {
    const { isOpen, width, height, customContentStyle, customOverlayStyle } =
      this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            ...customOverlayStyle,
          },
          content: customContentStyle || {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 0, // hack to make the modal NOT BLURRY
            width: width ? width : "60%",
            height: height ? height : "",
          },
        }}
        onAfterOpen={() => {
          // to prevent background scrolling
          document.body.style.overflow = "hidden";
        }}
        onAfterClose={() => {
          // Important! make background scrollable again
          document.body.style.overflow = "auto";
        }}
      >
        {this.props.children}

        {this.props.showCloseFooter && (
          <div className="border-t -mx-6 -mb-4 flex justify-end px-6 py-1">
            <button
              type="button"
              className="rounded flex-shrink-0 border-4 border-transparent px-2 py-1 text-sm font-semibold text-blue-500 hover:text-blue-800"
              onClick={this.handleCloseModal}
            >
              Close
            </button>
          </div>
        )}
      </ReactModal>
    );
  }
}

export default ModalWrapper;
