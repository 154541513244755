import React from "react";
import _ from "lodash";

import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import ReactTooltip from "react-tooltip";

import ModalWrapper from "../../../common/ModalWrapper";

import { PRICE_TYPE } from "constants/PriceType";
import { formatMoneyWithCurrency } from "../../../playground/home-dashboard-v2/ReportsHelper";

class BatchUpdateLabelModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelInput: "",
    };

    this.handleLabelInputChanged = this.handleLabelInputChanged.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    const { rules } = this.props;
    const labelList = _.union(_.map(rules, "label"));
    if (labelList.length === 1) {
      this.setState({
        labelInput: labelList[0],
      });
    }
  }

  handleLabelInputChanged(e) {
    this.setState({
      labelInput: e.target.value,
    });
  }

  handleConfirm() {
    const { labelInput } = this.state;
    this.props.handleConfirm(labelInput);
  }

  render() {
    const { isModalOpen, handleCloseModal, rules, networkInfo } = this.props;
    const { labelInput } = this.state;

    const inputClass =
      "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        showCloseFooter={false}
        handleClose={handleCloseModal}
        width="60%"
        height="70%"
      >
        <div className="flex h-full flex-col justify-between">
          <div className="mb-2 text-xl font-bold text-gray-700">
            Batch update label
          </div>
          <div
            className="border h-full overflow-y-auto px-8 py-6"
            // style={{ maxHeight: "620px" }}
          >
            <div className="mb-4">
              <label className="block font-bold text-gray-800" htmlFor="label">
                Label
              </label>
              <input
                className={inputClass}
                id="label"
                type="text"
                value={labelInput}
                onChange={this.handleLabelInputChanged}
              />
            </div>
            <label className="block font-bold text-gray-800">
              Selected rules
            </label>
            <table className="border shadow table w-full text-sm">
              <thead className="border-b bg-gray-200 text-xs text-blue-800">
                <tr>
                  <th className="border px-2 py-1 text-center">Enabled</th>
                  <th className="border px-2 py-1 text-right">ID</th>
                  <th className="border px-2 py-1 text-right">Name</th>
                  <th className="border px-2 py-1 text-center">Targeting</th>
                  <th className="border px-2 py-1 text-right">Prices</th>
                </tr>
              </thead>
              <tbody className="bg-white text-gray-900">
                {rules.map((item) => {
                  return (
                    <tr key={item.id} className="border-b hover:bg-gray-100">
                      <td className="border px-2 py-1 text-center">
                        <div className="flex items-center justify-center text-center text-lg">
                          {item.enabled ? (
                            <span className="text-green-600">
                              <FiCheckCircle></FiCheckCircle>
                            </span>
                          ) : (
                            <span className="text-red-600">
                              <FiXCircle></FiXCircle>
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="border px-2 py-1 text-right">
                        <span className="font-mono">{item.id}</span>
                      </td>

                      <td className="border px-2 py-1 text-right font-medium">
                        {item.name}
                      </td>

                      <td className="border px-2 py-1 text-center">
                        <div>
                          <button
                            data-tip
                            data-for={item.id + item.name}
                            type="button"
                            className="rounded cursor-default bg-blue-100 px-4 py-2 font-semibold text-blue-600 hover:bg-blue-200"
                          >
                            {Object.keys(item.targetingSection).length}
                          </button>
                          <ReactTooltip
                            id={item.id + item.name}
                            type="dark"
                            effect="solid"
                          >
                            {Object.keys(item.targetingSection).join(", ")}
                          </ReactTooltip>
                        </div>
                      </td>

                      <td className="whitespace-no-wrap border px-2 py-1 text-right">
                        <div className="-mx-2 px-2">
                          <div className="text-xs font-medium leading-none text-gray-600">
                            {PRICE_TYPE[item.priceSection.priceType]}
                          </div>
                          {item.priceSection.priceType !== -1 &&
                            item.priceSection.priceType !== 2 && (
                              <div className="font-mono">
                                {formatMoneyWithCurrency({
                                  currency: networkInfo.currency,
                                  value: item.priceSection.price,
                                })}
                              </div>
                            )}
                        </div>
                        {item.priceSection.brandedPriceSettings && (
                          <div className="border-t">
                            <div className="cursor-pointer hover:underline">
                              <div>
                                <span className="rounded bg-green-200 px-2 text-xs text-green-900">
                                  Branded (
                                  {
                                    item.priceSection.brandedPriceSettings
                                      .length
                                  }
                                  )
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex w-full pt-4">
            <div className="w-1/3"> </div>
            <div className="flex w-1/3 items-center justify-center"></div>
            <div className="flex w-1/3 items-center justify-end">
              <button
                type="button"
                className="px-4 py-2 text-blue-700"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="rounded shadow bg-blue-500 px-4 py-2  font-semibold text-white hover:bg-blue-700"
                onClick={this.handleConfirm}
              >
                Update labels
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default BatchUpdateLabelModal;
